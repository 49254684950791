import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import AuthService from "shared/services/AuthService";
import Api from "shared/resources/Api";
import AvatarService from "shared/services/AvatarService";
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpf: undefined,
      user: undefined,
      api: Api.baseURL,
      foto:""
    };
  }
 
  componentDidMount() {
    const cpf = localStorage.getItem("code")
      ? localStorage.getItem("code").replace(/\D/g, "")
      : undefined;
    if (cpf) {
      this.fetchUser(cpf)
        .then(res => {

          let user = res;
          this.setState({ user: user 
          });
        })
        .catch(err => console.log(err));
        AvatarService.getAvatar();
    }
  }

  fetchUser = async (cpf) => {
    let host = this.state.api + "/usuario/info";

    try {
        const response = await fetch(host, {
            headers: {
                Authorization: localStorage.getItem("agToken")
            }
        });

        console.log(response.status);

        if (response.status === 401) {
            // Handle the case when the error is "Invalid Authentication"
            this.logout();
            this.props.history.push("/admin/index");
        }

        const body = await response.json();
        return body;

    } catch (error) {
        // Log out and redirect to index in case of any error
        this.logout();
        this.props.history.push("/admin/index");
        console.error("Error fetching user:", error);
    }
};
  
  logout() {
    localStorage.removeItem("agToken");
    AuthService.signout();
  }
  myProfile()
  {
    this.props.history.push(`/admin/profile`);
    
  }
  myPassword()
  {
    this.props.history.push(`/admin/password`);
    
  }
  backPath = () => {


    
    if (
      
      this.props.backPath.indexOf("abandoned-property-edit") > -1 ||
      this.props.backPath.indexOf("registred-property-edit") > -1 ||
      this.props.backPath.indexOf("pool-edit") > -1 ||
      this.props.backPath.indexOf("biocontrol-edit") > -1 ||
      this.props.backPath.indexOf("tireshop-edit") > -1 ||
      this.props.backPath.indexOf("strategicpoint-edit") > -1 ||
      this.props.backPath.indexOf("ronda-edit") > -1 
    
    ) {
      // If 'edit' is found in backPath
      let path = this.props.backPath.split("-");
      console.log(path);
      if (path.length >= 2) {
        path.splice(path.length - 1, 1);
        path = path.join("-");
      }
      return "cadastroCriar";
    } else if (
      
      this.props.backPath.indexOf("abandoned-property-cad") > -1 ||
      this.props.backPath.indexOf("registred-property-cad") > -1 ||
      this.props.backPath.indexOf("pool-cad") > -1 ||
      this.props.backPath.indexOf("biocontrol-cad") > -1 ||
      this.props.backPath.indexOf("tireshop-cad") > -1 ||
      this.props.backPath.indexOf("strategicpoint-cad") > -1 ||
      this.props.backPath.indexOf("ronda-cad") > -1 

    ) {
      let path = this.props.backPath.split("-");
      console.log(path)
      if (path.length >= 2) {
        path.splice(path.length - 1, 1);
        path = path.join("-");
      }
      return "cadastroListar";
    } else if (
      
      this.props.backPath.indexOf("equipedit") > -1 

    ) {
      let path = this.props.backPath.split("-");
      console.log(path)
      if (path.length >= 2) {
        path.splice(path.length - 1, 1);
        path = path.join("-");
      }
      return "equipelist";
    } else if (
      
      this.props.backPath.indexOf("empresacad") > -1 

    ) {
      let path = this.props.backPath.split("-");
      console.log(path)
      if (path.length >= 2) {
        path.splice(path.length - 1, 1);
        path = path.join("-");
      }
      return "empresacad";
    } else if (
      
      this.props.backPath.indexOf("empresadit") > -1 

    ) {
      let path = this.props.backPath.split("-");
      console.log(path)
      if (path.length >= 2) {
        path.splice(path.length - 1, 1);
        path = path.join("-");
      }
      return "empresalist";
    }else if (
      
      this.props.backPath.indexOf("edit") > -1 ||
      this.props.backPath.indexOf("new") > -1
    ) {
      let path = this.props.backPath.split("-");
      console.log(path)
      if (path.length >= 2) {
        path.splice(path.length - 1, 1);
        path = path.join("-");
      }
      return path;
    } else if (
      this.props.backPath.indexOf("lab") > -1 
    ) {
      let path = this.props.backPath.split("-");
      console.log(path)
      if (path.length >= 2) {
        path.splice(path.length - 1, 1);
        path = path.join("-");
      }
      return "ovitramp";
    }else return "/";
  };

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0  text-uppercase d-none d-lg-inline-block"
              to={() => this.backPath()}
            >
              {this.props.backPath !== "/admin/index" && (
                <FontAwesomeIcon icon={faChevronLeft} className="edit" />
              )}{" "}
              {this.props.brandText}
            </Link>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                    {AvatarService.avatar !== '' && (
                          <img
                            alt='...'                           
                            src={AvatarService.avatar}
                          />
                        )}
                        {AvatarService.avatar === '' && (
                          <img
                            alt='...'                            
                            src={require('assets/img/brand/user.png')}
                          />
                        )}
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-default text-sm font-weight-bold">
                        {this.state.user && (
                          <>{this.state.user.nome.split(" ")[0]}</>
                        )}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bem Vindo!</h6>
                  </DropdownItem>
                  <DropdownItem to="/"onClick={() => this.myProfile()}>
                    <i className="ni ni-single-02" />
                    <span>Meu Perfil</span>
                  </DropdownItem>
                  <DropdownItem to="/"onClick={() => this.myPassword()}>
                    <i className="ni ni-lock-circle-open" />
                    <span>Alterar Senha</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={() => this.logout()}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
