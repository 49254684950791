
const DataMap = (dados) => {
  let pontos = [
    { id: 1, x: 550, y: 120, texto: 0, nome: "DISTRITO INDUSTRIAL", setor: "NORTE", exibir: 1 },
    { id: 2, x: 390, y: 242, texto: 0, nome: "MORADA DO SOL", setor: "OESTE", exibir: 1 },
    { id: 3, x: 450, y: 264, texto: 0, nome: "TOCANTINS", setor: "OESTE", exibir: 1 },
    { id: 4, x: 475, y: 230, texto: 0, nome: "GUARANI", setor: "OESTE", exibir: 1 },
    { id: 5, x: 500, y: 275, texto: 0, nome: "TAIAMAM", setor: "OESTE", exibir: 1 },
    { id: 6, x: 490, y: 305, texto: 0, nome: "JARDIM PATRICIA", setor: "OESTE", exibir: 1 },
    { id: 7, x: 535, y: 297, texto: 0, nome: "DONA ZULMIRA", setor: "OESTE", exibir: 1 },
    { id: 8, x: 450, y: 335, texto: 0, nome: "LUIZOTE DE FREITAS", setor: "OESTE", exibir: 1 },
    { id: 9, x: 445, y: 365, texto: 0, nome: "MANSOUR", setor: "OESTE", exibir: 1 },
    { id: 10, x: 435, y: 405, texto: 0, nome: "JARDIM EUROPA", setor: "OESTE", exibir: 1 },
    { id: 11, x: 405, y: 462, texto: 0, nome: "PANORAMA", setor: "OESTE", exibir: 1 },
    { id: 12, x: 390, y: 542, texto: 0, nome: "MONTE HEBRON", setor: "OESTE", exibir: 1 },
    { id: 13, x: 315, y: 585, texto: 0, nome: "MORADA NOVA", setor: "OESTE", exibir: 1 },
    { id: 14, x: 280, y: 509, texto: 0, nome: "RESIDENCIAL PEQUIS", setor: "OESTE", exibir: 1 },
    { id: 15, x: 463, y: 495, texto: 0, nome: "JARDIM CANAA", setor: "OESTE", exibir: 1 },
    { id: 16, x: 570, y: 348, texto: 0, nome: "JARAGUA", setor: "OESTE", exibir: 1 },
    { id: 17, x: 505, y: 352, texto: 0, nome: "CHACARAS TUBALINA E QUARTEL", setor: "OESTE", exibir: 1 },
    { id: 18, x: 530, y: 378, texto: 0, nome: "PLANALTO", setor: "OESTE", exibir: 1 },
    { id: 19, x: 524, y: 426, texto: 0, nome: "JARDIM DAS PALMEIRAS", setor: "OESTE", exibir: 1 },
    { id: 20, x: 520, y: 462, texto: 0, nome: "JARDIM HOLANDA", setor: "OESTE", exibir: 1 },
    { id: 21, x: 530, y: 534, texto: 0, nome: "ASSENTAMENTO IRMÃ DULCE (JD. CANAA)", setor: "OESTE", exibir: 1 },
    { id: 22, x: 680, y: 544, texto: 0, nome: "SHOPPING PARK", setor: "SUL", exibir: 1 },
    { id: 23, x: 600, y: 490, texto: 0, nome: "NOVA UBERLANDIA", setor: "SUL", exibir: 1 },
    { id: 24, x: 599, y: 440, texto: 0, nome: "CIDADE JARDIM", setor: "SUL", exibir: 1 },
    { id: 25, x: 599, y: 396, texto: 0, nome: "TUBALINA", setor: "SUL", exibir: 1 },
    { id: 26, x: 643, y: 387, texto: 0, nome: "PATRIMONIO", setor: "SUL", exibir: 1 },
    { id: 27, x: 668, y: 435, texto: 0, nome: "MORADA DA COLINA", setor: "SUL", exibir: 1 },
    //  { id: 28, x: 655, y: 474, texto: 0, nome:"IBIPORÃ", setor: "SUL", exibir: 1 },            //não tem
    { id: 28, x: 655, y: 474, texto: 0, nome: "GAVEA", setor: "SUL", exibir: 1 },            //ibiporã

    // { id: 29, x: 725, y: 491, texto: 0, nome:"BOSQUE KARAIBA", setor: "SUL", exibir: 1 },    //não tem
    { id: 29, x: 725, y: 491, texto: 0, nome: "JARDIM SUL", setor: "SUL", exibir: 1 },    //não tem
    { id: 30, x: 810, y: 490, texto: 0, nome: "LARANJEIRAS", setor: "SUL", exibir: 1 },
    { id: 31, x: 876, y: 471, texto: 0, nome: "SAO JORGE", setor: "SUL", exibir: 1 },
    { id: 32, x: 860, y: 417, texto: 0, nome: "SANTA LUZIA", setor: "SUL", exibir: 1 },
    { id: 33, x: 808, y: 435, texto: 0, nome: "GRANADA", setor: "SUL", exibir: 1 },
    { id: 34, x: 715, y: 431, texto: 0, nome: "JARDIM KARAIBA", setor: "SUL", exibir: 1 },
    { id: 35, x: 700, y: 382, texto: 0, nome: "VIGILATO PEREIRA", setor: "SUL", exibir: 1 },
    { id: 36, x: 720, y: 343, texto: 0, nome: "SARAIVA", setor: "SUL", exibir: 1 },
    { id: 37, x: 749, y: 371, texto: 0, nome: "LAGOINHA", setor: "SUL", exibir: 1 },
    { id: 38, x: 760, y: 410, texto: 0, nome: "JARDIM INCONFIDENCIA", setor: "SUL", exibir: 1 },
    { id: 39, x: 784, y: 380, texto: 0, nome: "CARAJAS", setor: "SUL", exibir: 1 },
    { id: 40, x: 819, y: 390, texto: 0, nome: "PAMPULHA", setor: "SUL", exibir: 1 },
    { id: 41, x: 599, y: 323, texto: 0, nome: "DANIEL FONSECA", setor: "CENTRO", exibir: 1 },
    { id: 42, x: 618, y: 300, texto: 0, nome: "OSVALDO REZENDE", setor: "CENTRO", exibir: 1 },  //corrigir nome
    { id: 43, x: 655, y: 290, texto: 0, nome: "MARTINS", setor: "CENTRO", exibir: 1 },
    { id: 44, x: 685, y: 268, texto: 0, nome: "BOM JESUS", setor: "CENTRO", exibir: 1 },
    { id: 45, x: 730, y: 233, texto: 0, nome: "BRASIL", setor: "CENTRO", exibir: 1 },
    { id: 46, x: 710, y: 279, texto: 0, nome: "NOSSA SENHORA APARECIDA", setor: "CENTRO", exibir: 1 },
    { id: 47, x: 680, y: 314, texto: 0, nome: "CENTRO", setor: "CENTRO", exibir: 1 },
    { id: 48, x: 725, y: 310, texto: 0, nome: "CAZECA", setor: "CENTRO", exibir: 1 },
    { id: 49, x: 693, y: 345, texto: 0, nome: "LIDICE", setor: "CENTRO", exibir: 1 },
    { id: 50, x: 660, y: 340, texto: 0, nome: "FUNDINHO", setor: "CENTRO", exibir: 1 },
    { id: 51, x: 620, y: 355, texto: 0, nome: "TABAJARAS", setor: "CENTRO", exibir: 1 },
    { id: 52, x: 930, y: 362, texto: 0, nome: "NOVO MUNDO", setor: "LESTE", exibir: 1 },
    { id: 53, x: 980, y: 342, texto: 0, nome: "ALVORADA", setor: "LESTE", exibir: 1 },
    { id: 54, x: 1030, y: 307, texto: 0, nome: "MORUMBI", setor: "LESTE", exibir: 1 },
    { id: 55, x: 960, y: 279, texto: 0, nome: "RESIDENCIAL INTEGRACAO", setor: "LESTE", exibir: 1 },
    { id: 56, x: 989, y: 230, texto: 0, nome: "GRAND VILLE", setor: "LESTE", exibir: 1 },
    { id: 57, x: 920, y: 241, texto: 0, nome: "MANSOES AEROPORTO", setor: "LESTE", exibir: 1 },
    { id: 58, x: 875, y: 347, texto: 0, nome: "SEGISMUNDO PEREIRA", setor: "LESTE", exibir: 1 },
    { id: 59, x: 797, y: 322, texto: 0, nome: "SANTA MONICA", setor: "LESTE", exibir: 1 },
    { id: 60, x: 853, y: 300, texto: 0, nome: "SABIA", setor: "LESTE", exibir: 1 },           // não tem
    { id: 61, x: 783, y: 270, texto: 0, nome: "TIBERY", setor: "LESTE", exibir: 1 },
    { id: 62, x: 850, y: 250, texto: 0, nome: "CUSTODIO PEREIRA", setor: "LESTE", exibir: 1 },
    { id: 63, x: 750, y: 195, texto: 0, nome: "UMUARAMA", setor: "LESTE", exibir: 1 },
    { id: 64, x: 830, y: 210, texto: 0, nome: "ALTO UMUARAMA", setor: "LESTE", exibir: 1 },
    //{ id: 65, x: 800, y: 140, texto: 0, nome:"ALPHAVILE", setor: "LESTE", exibir: 1 },       //NÃO TEM

    { id: 65, x: 800, y: 140, texto: 0, nome: "GRANJA MARILEUSA", setor: "LESTE", exibir: 1 },       //NÃO TEM
    { id: 66, x: 857, y: 158, texto: 0, nome: "ACLIMACAO", setor: "LESTE", exibir: 1 },
    { id: 67, x: 890, y: 186, texto: 0, nome: "JARDIM IPANEMA", setor: "LESTE", exibir: 1 },
    { id: 68, x: 910, y: 140, texto: 0, nome: "MORADA DOS PASSAROS", setor: "LESTE", exibir: 1 },
    { id: 69, x: 715, y: 122, texto: 0, nome: "MINAS GERAIS", setor: "NORTE", exibir: 1 },
    { id: 70, x: 712, y: 185, texto: 0, nome: "MARTA HELENA", setor: "NORTE", exibir: 1 },
    { id: 71, x: 680, y: 151, texto: 0, nome: "NOSSA SENHORA DAS GRACAS", setor: "NORTE", exibir: 1 },
    { id: 72, x: 650, y: 178, texto: 0, nome: "SANTA ROSA", setor: "NORTE", exibir: 1 },
    { id: 73, x: 620, y: 189, texto: 0, nome: "PACAEMBU", setor: "NORTE", exibir: 1 },
    { id: 74, x: 590, y: 202, texto: 0, nome: "MARAVILHA", setor: "NORTE", exibir: 1 },
    { id: 75, x: 575, y: 240, texto: 0, nome: "JARDIM BRASILIA", setor: "NORTE", exibir: 1 },
    { id: 76, x: 545, y: 264, texto: 0, nome: "SAO JOSE", setor: "NORTE", exibir: 1 },
    { id: 77, x: 635, y: 243, texto: 0, nome: "PRESIDENTE ROOSEVELT", setor: "NORTE", exibir: 1 },
    /**fORA DO MAPA */
    { id: 78, x: 0, y: 0, texto: 0, nome: "AREA RURAL DE UBERLANDIA", setor: "RURAL", exibir: 0 },
    { id: 79, x: 0, y: 0, texto: 0, nome: "ASSENTAMENTO DOM JOSE MAURO", setor: "RURAL", exibir: 0 },
    { id: 80, x: 0, y: 0, texto: 0, nome: "ASSENTAMENTO TANGARA", setor: "RURAL", exibir: 0 },
    { id: 81, x: 0, y: 0, texto: 0, nome: "BONANZA (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 82, x: 0, y: 0, texto: 0, nome: "DISTRITO CRUZEIRO DOS PEIXOTOS", setor: "DISTRITO", exibir: 0 },
    { id: 83, x: 0, y: 0, texto: 0, nome: "DISTRITO MARTINESIA", setor: "DISTRITO", exibir: 0 },
    { id: 84, x: 0, y: 0, texto: 0, nome: "DISTRITO MIRAPORANGA", setor: "DISTRITO", exibir: 0 },
    { id: 85, x: 0, y: 0, texto: 0, nome: "DISTRITO TAPUIRAMA", setor: "DISTRITO", exibir: 0 },
    { id: 86, x: 0, y: 0, texto: 0, nome: "DOURADINHO (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 87, x: 0, y: 0, texto: 0, nome: "ELDORADO (CHACARAS)", setor: "RURAL", exibir: 0 },
    //{ id: 88, x: 0, y: 0, texto: 0, nome: "GAVEA", setor: "SUL", exibir: 0 }, IBIPORA
    { id: 89, x: 0, y: 0, texto: 0, nome: "GRANJA MARILEUSA", setor: "LESTE", exibir: 0 }, // ALPHAVILE
    //{ id: 90, x: 0, y: 0, texto: 0, nome: "JARDIM SUL", setor: "SUL", exibir: 0 }, // BOSQUE KARAIBA
    { id: 91, x: 0, y: 0, texto: 0, nome: "LOT LUIZOTE IV", setor: "OESTE", exibir: 0 },
    { id: 92, x: 0, y: 0, texto: 0, nome: "MANSOUR III", setor: "OESTE", exibir: 0 },
    { id: 93, x: 0, y: 0, texto: 0, nome: "MIRANDA II (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 94, x: 0, y: 0, texto: 0, nome: "NAO IDENTIFICADO", setor: "NAO IDENTIFICADO", exibir: 0 },
    { id: 95, x: 0, y: 0, texto: 0, nome: "OLHOS D'AGUA (AREA RURAL)", setor: "RURAL", exibir: 0 },
    { id: 96, x: 0, y: 0, texto: 0, nome: "OLIVEIRA (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 97, x: 0, y: 0, texto: 0, nome: "PARQUE DAS AMERICAS (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 98, x: 0, y: 0, texto: 0, nome: "PARQUE DAS ANDORINHAS (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 99, x: 0, y: 0, texto: 0, nome: "PARQUE MARAVILHA (CHACARAS )", setor: "RURAL", exibir: 0 },
    { id: 100, x: 0, y: 0, texto: 0, nome: "PORTAL DO VALE", setor: "LESTE", exibir: 0 },
    { id: 101, x: 0, y: 0, texto: 0, nome: "RANCHO ALEGRE (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 102, x: 0, y: 0, texto: 0, nome: "RESIDENCIAL FRUTA DO CONDE", setor: "OESTE", exibir: 0 },
    { id: 103, x: 0, y: 0, texto: 0, nome: "RESIDENCIAL GRAMADO", setor: "NORTE", exibir: 0 },
    { id: 104, x: 0, y: 0, texto: 0, nome: "RESIDENCIAL LAGO AZUL", setor: "OESTE", exibir: 0 },
    { id: 105, x: 0, y: 0, texto: 0, nome: "VALE DO SOL DISTRITO CRUZEIRO DOS PEIXOTOS", setor: "DISTRITO", exibir: 0 },
    { id: 106, x: 0, y: 0, texto: 0, nome: "VALPARAISO (CHACARAS)", setor: "RURAL", exibir: 0 },
    { id: 107, x: 0, y: 0, texto: 0, nome: "VILA MARIELZA (LOTEAMENTO)", setor: "RURAL", exibir: 0 }
  ];

  let tmp = [];
  let tmp2 = '';
  if (dados && dados.length > 0) {
    pontos.map(ponto => {
      const dadoEncontrado = dados.find(dado => {
        if (dado && dado.type) {
          return dado.type.toUpperCase().includes(ponto.nome.toUpperCase());
        }
        return false;
      });
      tmp2 += ",'" + ponto.nome + "'";
      if (dadoEncontrado) {
        tmp.push({
          ...ponto,
          texto: dadoEncontrado.value.toString()
        });
      }
      else {
        tmp.push(ponto);
      }
    });
    pontos = tmp;
  }



  return pontos;

};
const calcZone = (dados) => {
  let zon = [{
    name: 'CENTRO',
    Casos: 0
  }, {
    name: 'NORTE',
    Casos: 0
  }, {
    name: 'SUL',
    Casos: 0
  }, {
    name: 'LESTE',
    Casos: 0
  }, {
    name: 'OESTE',
    Casos: 0
  }, {
    name: 'RURAL',
    Casos: 0
  }, {
    name: 'DISTRITO',
    Casos: 0
  }, {
    name: 'NAO IDENTIFICADO',
    Casos: 0
  }
  ];
  if (dados.length > 0) {
    dados.forEach(item => {
      const setor = item.setor;
      const valor = parseInt(item.texto) || 0;

      const zona = zon.find(z => z.name === setor);
      if (zona) {
        zona.Casos += valor;
      }
    });
  }
  return zon;
};
const getType = (dados) => {
  let tmp = "";
  if(dados == "day"){
    tmp = "DIÁRIO";
  }else if(dados == "week"){ 
    tmp = "SEMANAL";
  }else if(dados == "month"){
    tmp = "MENSAL";
  }else if(dados == "year"){
    tmp = "ANUAL";
  }
  return tmp;
};
export { DataMap, calcZone, getType };
