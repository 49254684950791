import React, { useEffect, useState } from "react";
import { Map as MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Row } from "reactstrap";
import L from "leaflet";
import vermelho from "../../assets/img/vermelho.png";
import verde from "../../assets/img/verde.png";
import amarelo from "../../assets/img/amarelo.png";
import Swal from "sweetalert2";


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapsLeaflet = ({ dados = [], setor = [], title = 'MAPA DE OVITRAMPAS' }) => {
  const [positionInit, setPositionInit] = useState([-18.918, -48.2766]);
  const [location, setLocation] = useState([]);
  const [tipo, setTipo] = useState('NR_OVOS_VIAVEIS_');
  const [tipoCount, setTipoCount] = useState('IA');
  const [position, setPosition] = useState(positionInit);
  const [search, setSearch] = useState('');
  const [zoom, setZoom] = useState(12);
  const [clickedMarker, setClickedMarker] = useState(null);


  const centralizar = () => {
    if (search.length > 0) {
      let ovitrampa = dados.find(item => parseInt(item.OID_OVITRAMPA) === parseInt(search));
      if (ovitrampa) {
        if(position[0] == ovitrampa.DS_LATITUDE && position[1] == ovitrampa.DS_LONGITUDE){
          ovitrampa.DS_LATITUDE = ovitrampa.DS_LATITUDE + '1';
          ovitrampa.DS_LONGITUDE = ovitrampa.DS_LONGITUDE + '1';
        }

        setPosition([ovitrampa.DS_LATITUDE, ovitrampa.DS_LONGITUDE]);
          ovitrampa.DS_LATITUDE = ovitrampa.DS_LATITUDE + '1';
          ovitrampa.DS_LONGITUDE = ovitrampa.DS_LONGITUDE + '1';
        setPosition([ovitrampa.DS_LATITUDE, ovitrampa.DS_LONGITUDE]);

        if(zoom == 16){
          setZoom(17);
        }else{
          setZoom(16);
        }
              
        //setClickedMarker(ovitrampa.OID_OVITRAMPA);
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'Ovitrampa não encontrada',
          icon: 'warning',
          timer: 2000,
          showConfirmButton: false
        });
      }
    }
  }
  useEffect(() => {
    setLocation(dados);
  }, [dados]);
  useEffect(() => {

    const elements = document.getElementsByClassName('leaflet-attribution-flag');
    for (let element of elements) {
      element.style.setProperty("display", "none", "important");
    }
  }, []);
  const Vermelho = new L.Icon({
    iconUrl: vermelho,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
  const Amarelo = new L.Icon({
    iconUrl: amarelo,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });
  const Verde = new L.Icon({
    iconUrl: verde,
    iconSize: [24, 32],
    iconAnchor: [8, 16],
    popupAnchor: [0, -16],
  });

  return (
    <React.Fragment>
      <Row>
        <div style={{ top: '-18px', width: "100vw", height: "100vh", position: "relative" }}>
          <h1 style={{
            textAlign: "center",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            backgroundColor: "rgba(255,255,255,0.5)",
            margin: 0,
            padding: "10px 20px",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            display: "inline-block"
          }}>{title}</h1>
          <select
            value={tipoCount}
            onChange={(e) => setTipoCount(e.target.value)}
            style={{
              textAlign: "left",
              position: "absolute",
              left: "70px",
              top: "10px",
              zIndex: 1000,
              backgroundColor: "rgba(255,255,255,0.5)",
              margin: 0,
              padding: "10px 20px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              border: "1px solid #ccc"
            }}>
            <option value="IA">Contagem por IA</option>
            <option value="LAB">Contagem Laboratorial</option>
          </select>
          <select
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            style={{
              textAlign: "left",
              position: "absolute",
              left: "70px",
              top: "60px",
              zIndex: 1000,
              backgroundColor: "rgba(255,255,255,0.5)",
              margin: 0,
              padding: "10px 20px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              border: "1px solid #ccc"
            }}>
            <option value="NR_OVOS_VIAVEIS_">Ovos Dengue</option>
            {/* <option value="NR_OVOS_INVIAVEIS_">Ovos inviáveis</option> */}
            <option value="NR_OVOS_ECLODIDOS_">Ovos Eclodidos</option>
          </select>
          <input
            type="number"
            placeholder="Pesquisar ovitrampa..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                centralizar();
              }
            }}
            style={{
              position: "absolute",
              right: "180px",
              top: "20px",
              zIndex: 1001,
              backgroundColor: "rgba(255,255,255,0.8)",
              padding: "8px 15px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              width: "200px"
            }}
          />
          <button
            style={{
              position: "absolute",
              right: "130px",
              top: "20px",
              zIndex: 1001,
              backgroundColor: "rgb(24 202 255 / 38%)",
              padding: "8px 15px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              cursor: "pointer"
            }}
            onClick={() => centralizar()}
          >
            <i className="fas fa-search"></i>
          </button>
          <MapContainer
            center={position}
            zoom={zoom}
            style={{ height: "100vh", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {location?.map((data, key) => (
              <Marker
                key={data.OID_OVITRAMPA}
                ref={(ref) => {
                  if (clickedMarker === data.OID_OVITRAMPA && ref) {
                    ref.leafletElement.openPopup();
                  }
                }}
                position={[data.DS_LATITUDE, data.DS_LONGITUDE]}
                icon={data.TP_SITUACAO === 'VERMELHO' ? Vermelho : data.TP_SITUACAO === 'AMARELO' ? Amarelo : Verde}
              
              >{
                  <Popup 
                  onClose={() => {
                    setClickedMarker(null);
                  }}
                  >
                    <div style={{ fontSize: '16px' }}>
                      <strong>N° Ovitrampa: {data.OID_OVITRAMPA}</strong>
                      <br />
                      <strong>Responsável: {data.NM_USUARIO}</strong>
                      <br />
                      <strong>Situação: {data.TP_SITUACAO}</strong>
                      <br />
                      <strong>Setor: {data.NM_SETOR}</strong>
                      <br />
                      <strong>Endereço: {data.NM_LOGRADOURO}</strong>
                      <br />
                      <strong>Número: {data.NR_NUMERO}</strong>
                      <br />
                      <strong>Bairro: {data.NM_BAIRRO}</strong>
                      <br />
                      <strong>Número de ovos: {data[tipo + tipoCount]}</strong>
                      <br />

                    </div>
                  </Popup>}
              </Marker>
            ))}
          </MapContainer>
        </div>

        <div style={{
          position: "fixed",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1000,
          backgroundColor: "rgba(255,255,255,0.8)",
          padding: "15px 25px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
          <div style={{
            display: "flex",
            gap: "20px",
            justifyContent: "center"
          }}>
            <div><strong>Centro: {setor[0] ? setor[0][tipo + tipoCount] : 0}</strong></div>
            <div><strong>Norte: {setor[1] ? setor[1][tipo + tipoCount] : 0}</strong></div>
            <div><strong>Sul: {setor[2] ? setor[2][tipo + tipoCount] : 0}</strong></div>
            <div><strong>Leste: {setor[3] ? setor[3][tipo + tipoCount] : 0}</strong></div>
            <div><strong>Oeste: {setor[4] ? setor[4][tipo + tipoCount] : 0}</strong></div>
            <div><strong>Outros: {setor[5] ? setor[5][tipo + tipoCount] : 0}</strong></div>

          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default MapsLeaflet;
