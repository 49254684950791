import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { useLocation } from 'react-router-dom';
import { equipes } from "shared/services/PlacesService";

import SweetAlert2 from "react-sweetalert2";
import InputMask from "react-input-mask";
import InputNumber from "react-input-just-numbers";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { cpfMask } from "shared/services/Mask";
import { sectors, neighborhood } from "shared/services/PlacesService";

import Header from "components/Headers/Header.js";
import Util from "shared/services/Util";
import Api from "shared/resources/Api";
import moment from "moment";
import ReactExport from "react-data-export";
import ExcelFile from "../../shared/ExcelPlugin/components/ExcelFile"

// const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let isShow = false;




// if (localStorage.getItem("gridos")) {
//   var obj = JSON.parse(localStorage.getItem("gridos"));
//   console.log("obs"+obj)
// }




export default class TicketList extends React.Component {

  state = {
    tickets: [],
  };
  
  /* Dados Grid */
  
  columns = [
    {
      name: "O.S",
      selector: "id",
      sortable: true,
    },
    {
      name: "Origem",
      selector: "origem",
      sortable: true,
      cell: (row) => {
        const origemValue = row.origem;
        let displayedText = "";
    
        switch (origemValue) {
          case "drones":
            displayedText = "Monitoramento";
            break;
          case "email":
            displayedText = "E-mail";
            break;
          case "faleconosco":
            displayedText = "Fale Conosco";
            break;
          case "municepe":
            displayedText = "Munícipe";
            break;
          case "origem_tecnica":
            displayedText = "Origem Técnica";
            break;
          case "ouvidoria":
            displayedText = "Ouvidoria";
            break;
          case "sim":
            displayedText = "SIM";
            break;
          case "telefone":
            displayedText = "Telefone";
            break;
            case "Aplicativo":
            displayedText = "Aplicativo";
            break;
          default:
            displayedText = "Desconhecida";
            break;
        }
    
        return <div>{displayedText}</div>;
      },
    },
    {
      name: "Equipe",
      selector: "Equipe",
      sortable: true,
      cell: (row) => (
        <div>{row.equipe ? Util.formatEquipe2(row.equipe) : "-"}</div>
      ),
    },
    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
      cell: (row) => <div>{row.endereco ? row.endereco.logradouro : "-"}</div>,
      wrap: true,
    },
    {
      name: "Numero",
      selector: "numero",
      sortable: true,
      cell: (row) => <div>{row.endereco ? row.endereco.numero : "-"}</div>,
    },
    {
      name: "Bairro",
      selector: "bairro",
      sortable: true,
      cell: (row) => <div>{row.endereco ? row.endereco.bairro : "-"}</div>,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => <div>{Util.capitalize(row.status)}</div>,
    },
    {
      name: "Data Abertura",
      selector: "data_abertura",
      sortable: true,
      cell: (row) => (
        <div>{moment(moment(row.data_abertura)).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      name: "Agendamento",
      selector: "agendamento",
      sortable: true,
      cell: (row) => (
        <div>
          {this.handleAgendamento(
            row.agendamento
              ? moment(moment(row.agendamento)).format("DD/MM/YYYY")
              : "-"
          )}
        </div>
      ),
    },
    {
      name: "Tipo",
      selector: "tipo",
      sortable: true,
      cell: (row) => {
        if (row.tipo === "solicitacao") {
          const selectedValue = row.tipo_de_imovel;
          let displayedText = "";
    
          switch (selectedValue) {
            case "via_publica":
              displayedText = "Via Pública";
              break;
            case "recolhimento":
              displayedText = "Recolhimento";
              break;
            case "peixes":
              displayedText = "Tratamento Biológico";
              break;
            case "obras":
              displayedText = "Canteiros de Obra";
              break;
            case "imovel_abandonado":
              displayedText = "Imóvel Abandonado";
              break;
            case "imobiliarias":
              displayedText = "Imobiliárias";
              break;
            case "focos":
              displayedText = "Focos do Mosquito";
              break;
            case "piscinas":
              displayedText = "Cadastrar Piscina";
              break;
            case "caixa_dagua":
              displayedText = "Caixas d'Água";
              break;
              case "residencia":
                displayedText = "Residência";
                break;
            default:
              break;
          }
    
          return <div>{Util.capitalize(displayedText)}</div>;
        } else {
          return <div>{Util.capitalize(row.tipo)}</div>;
        }
      },
    },
    {
      name: "Data Ult. Movimentação",
      selector: "Data Ult. Movimentação",
      sortable: true,
      cell: (row) => {
        const horario = row.acoes
          .map((acao) => new Date(acao.horario))
          .sort((a, b) => b - a)[0];
        return (
          <div>
            {horario
              ? horario.toLocaleString("pt-BR", {
                  timeZone: "America/Sao_Paulo",
                  dateStyle: "short",
                  timeStyle: "short",
                })
              : "N/A"}
          </div>
        );
      },
    },

    {
      name: "",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Editar"
            onClick={() => {
              this.props.history.push("/admin/ticket-edit", {
                selectedOs: row,
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
          </Button>
          {localStorage.getItem("perm") !== "agente" && localStorage.getItem("perm") !== "Operador" && (
            <Button
              type="button"
              className="btn-acao "
              title="Remover"
              onClick={() => {
                this.handleDelete(row);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="remove" />
            </Button>
          )}
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
      },
    },
  };

  
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      currentPage: 1,
      cpf: "",
      tel: "",
      data_inicio: "",
      data_abertura_fim: moment().format("DD/MM/YYYY"),
      data_abertura_inicio: moment().subtract(1, "months").format("DD/MM/YYYY"),
      data_fim: "",
      agente: "",
      response: "",
      local: "",
      id: "",
      api: Api.baseURL,
      statusOS: "todos",
      os: [],
      equipes: [],
      tipo: "",
      requesting: false,
      logradouro: "",
      neighborhood: [],
      quarteirao: "",
      lado: "",
      show: false,
      numero: "",
    };
    this.show = false;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  
  // componentDidUpdate(prevProps, prevState) {

  //   if (localStorage.getItem("params")) {
  //     var obj = JSON.parse(localStorage.getItem("gridos"));
  //     this.setState({
  //       paramse: obj,
  //     });
  //   }
    
  // }
  
  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      localStorage.setItem("page", this.state.currentPage);
      console.log(this.state.currentPage);
      this.setState({ response: this.state.response });
      
    });
  };

  componentWillMount() {

    localStorage.setItem("excel", "OrdensServiço");

    

    if (localStorage.getItem("show")) {
      isShow =  true;
      //isShow = localStorage.getItem("show") === "true" ?? false;
      this.forceUpdate();
    }


    if (localStorage.getItem("params")) {
     
      try {
        var obj = (localStorage.getItem("params"));
        console.log(obj.tipo)
        console.log(JSON.stringify(localStorage.params));
        var para = JSON.parse(localStorage.getItem("params"));
        var tipo = para.tipo;
       

       let reload = {
        data_abertura_inicio: para.data_abertura_inicio !== undefined ? para.data_abertura_inicio : "",
        data_abertura_fim: para.dataFim !== undefined ? para.dataFim : "",
        agendamento_inicio: para.dataIniAge !== undefined ? para.dataIniAge : "",
        agendamento_fim: para.dataFimAge !== undefined ? para.dataFimAge : "",
        id: para.id,
        tipo_de_imovel: para.tipo_de_imovel,
        local: para.local  !== undefined ? para.local : "",
        tel: para.tel  !== undefined ? para.tel : "",
        lado: para.lado,
        quarteirao: para.quarteirao,
        cpf: para.cpf,
        agente: para.agente,
        status: para.status,
        origem: para.origem,
        equipe: para.equipe,
        logradouro: para.logradouro,
        bairro: para.bairro,
      };
      let endpoint = "os/solicitacao";
      let esc = encodeURIComponent;
      let query = Object.keys(reload)
      .map((k) => esc(k) + "=" + esc(reload[k]))
      .join("&");

      

      let host = this.state.api + `/${endpoint}?status=`;

      let path =
      reload.status === "todos"
        ? "&" + query
        : "" + "&" + query;

        const respons2 = fetch(host + path, {
          headers: {
            Authorization: localStorage.getItem("agToken"),
          },
        })
        .then((respons2) => {
          if (!respons2.ok) {
            throw new Error("Network response was not ok");
          }
          return respons2.json();
        })
        .then((body) => {
          console.log("body"+body)
          localStorage.setItem("gridos", JSON.stringify(body));
          var obj2 = JSON.parse(localStorage.getItem("gridos"));
          this.setState({
            response: obj2,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });


      console.log(reload)
        // Do something with the parsed object
      } catch (e) {
        console.error("Invalid JSON string:", e);
      }
    }
    
    
  //  const location = useLocation();
  //console.log(localStorage.getItem("gridos"))
  //const isVariableTrue = location.state.isVariableTrue;
    // const { isVariableTrue } = this.props.location.state? this.props.location.state : false ;
    // console.log(isVariableTrue);

   // console.log("aa"+location)
    if (
      this.props.location != null &&
      this.props.location.state != null 
     // this.props.location.state.isVariableTrue != true
    ) {
     

      //  this.setState({
      //   selectedOs: this.props.location.state.selectedOs,
        
      // });
    } 

    document.addEventListener("keydown", (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.handleSubmit(event);
      }
    });

    if (localStorage.getItem("gridos")) {
      var obj = JSON.parse(localStorage.getItem("gridos"));
      this.setState({
        response: obj,
      });
    }

    if (localStorage.getItem("page")) {
      var obj = JSON.parse(localStorage.getItem("page")); // Parse the JSON string
      this.setState(
        {
          currentPage: obj,
          response: this.state.response, // You can omit this line as it's not necessary
        },
        () => {
          // Optional callback function to perform actions after state update
          this.forceUpdate(); // Perform the force update after state is set
        }
      );
    }

    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });

    sectors().then((res) => {
      if (res) {
        this.setState({
          sectors: res,
        });
      }
    });

    equipes().then((res) => {
      if (res) {
        this.setState({
          equipes: res,
        });
      }
    });
  }

  fetchEquipes = async () => {
    const response = await fetch(Api.baseURL + "/unidade_saude/equipe", {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });
    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        sectors: body,
      });
    }

    return body;
  };

  handleDelete = (element) => {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Deseja realizar a exclusão deste registro?",
        type: "warning",
        showCancelButton: true,
        showConfirmButton: true,
      },
      os: element,
      isConfirmation: true,
    });
  };

  handleDeleteOs(d) {
    this.callDelOs(d)
      .then()
      .catch((err) => console.log(err));
  }

  callDelOs = async (d) => {
    const response = await fetch(Api.baseURL + "/os/" + d.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (response.status === 200) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: "O.S deletada com sucesso!",
          type: "success",
        },
        cpf: "",
        data_cadastro_inicio: "",
        data_cadastro_fim: "",
        nome: "",
        tel: "",
        response: "",
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Erro ao deletar O.S.",
          type: "error",
        },
      });

      throw Error(body.message);
    }

    return body;
  };

  handleChange(event) {
    this.setState({ cpf: cpfMask(event.target.value) });
  }

  handleSubmit(event) {
    this.state.show = true;
    
    //
    this.Min()
    this.setState({
      currentPage: 1,
    });
    
    this.dataIni = undefined;
    this.dataFim = undefined;
    this.dataIniAge = undefined;
    this.dataFimAge = undefined;
    if (this.validate()) {
      this.setState({ requesting: true });
      this.fetchOs()
        .then((res) =>
          this.setState({ response: res }, () => {
            localStorage.setItem("gridos", JSON.stringify(res));
          })
        )
        .then()
        .catch((err) => console.log(err));

      event.preventDefault();
    }
   
  }

  validate() {
    if (this.state.cpf !== "") {
      if (!Util.validaCPF(this.state.cpf.replace(/\D/g, ""))) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "CPF inválido.",
            type: "error",
          },
        });
        return false;
      }
    }

    if ((this.state.logradouro === undefined || this.state.logradouro === "") && this.state.numero.length !== 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro ou remova o Número.",
          type: "warning",
        },
      });

      return false;
    }

    if (this.state.tel !== "") {
      if (this.state.tel.replace(/\D/g, "").length !== 11) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Telefone inválido.",
            type: "error",
          },
        });
        return false;
      }
    }

    var dateFormat = "DD/MM/YYYY";

    if (
      this.state.data_abertura_inicio !== undefined &&
      this.state.data_abertura_inicio !== ""
    ) {
      if (
        !moment(this.state.data_abertura_inicio, dateFormat, true).isValid()
      ) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de abertura inicial inválida",
            type: "error",
          },
        });
        return false;
      } else {
        this.dataIni = moment(
          moment(this.state.data_abertura_inicio, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (
      this.state.data_abertura_fim !== undefined &&
      this.state.data_abertura_fim !== ""
    ) {
      if (!moment(this.state.data_abertura_fim, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de abertura final inválida",
            type: "error",
          },
        });
        return false;
      } else {
        this.dataFim = moment(
          moment(this.state.data_abertura_fim, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (this.dataIni !== undefined && this.dataFim !== undefined) {
      var date1 = moment(this.state.data_abertura_inicio, "DD/MM/YYYY");
      var date2 = moment(this.state.data_abertura_fim, "DD/MM/YYYY");
      var diff = date2.diff(date1);

      if (diff < 0) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de Início do chamado menor que Data Final.",
            type: "error",
          },
        });
        return false;
      }
    }

    if (
      this.state.agendamento_inicio !== undefined &&
      this.state.agendamento_inicio !== ""
    ) {
      if (!moment(this.state.agendamento_inicio, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de agendamento inicial inválida",
            type: "error",
          },
        });
        return false;
      } else {
        this.dataIniAge = moment(
          moment(this.state.agendamento_inicio, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (
      this.state.agendamento_fim !== undefined &&
      this.state.agendamento_fim !== ""
    ) {
      if (!moment(this.state.agendamento_fim, dateFormat, true).isValid()) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de agendamento final inválida",
            type: "error",
          },
        });
        return false;
      } else {
        this.dataFimAge = moment(
          moment(this.state.agendamento_fim, "DD/MM/YYYY")
        ).format("YYYY-MM-DD");
      }
    }

    if (this.dataIniAge !== undefined && this.dataFimAge !== undefined) {
      var date3 = moment(this.state.agendamento_inicio, "DD/MM/YYYY");
      var date4 = moment(this.state.agendamento_fim, "DD/MM/YYYY");
      var diff2 = date4.diff(date3);

      if (diff2 < 0) {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Data de Início do Agendamento do chamado menor que Data Final do Agendamento.",
            type: "error",
          },
        });
        return false;
      }
    }

    if (this.state.data_abertura_inicio === "") {
      this.dataIni = undefined;
    }

    if (this.state.data_abertura_fim === "") {
      this.dataFim = undefined;
    }

    if (this.state.agendamento_inicio === "") {
      this.dataIniAge = undefined;
    }

    if (this.state.agendamento_fim === "") {
      this.dataFimAge = undefined;
    }

    return true;
  }

  handleAgendamento = (text) => {
    if (text === "-") {
      return <label style={{ paddingTop: "19%" }}>{text}</label>;
    } else {
      return (
        <label
          style={{ color: "#5cb85c", paddingTop: "19%", fontWeight: "bold" }}
        >
          {text}
        </label>
      );
    }
  };
  MinMax = () => {
    // localStorage.setItem('show', isShow ? "false" : "true");
    //  isShow = localStorage.getItem("show") === "true" ?? false;

    //  this.forceUpdate();
  };

  Min = () => {

    // localStorage.setItem('show', "false");
    // isShow = localStorage.getItem("show") === "true" ?? false;
 
    // this.forceUpdate();
 };

 

 fetchOsload = async () => {
  let params = {
    data_abertura_inicio: this.dataIni !== undefined ? this.dataIni : "",
    data_abertura_fim: this.dataFim !== undefined ? this.dataFim : "",
    agendamento_inicio: this.dataIniAge !== undefined ? this.dataIniAge : "",
    agendamento_fim: this.dataFimAge !== undefined ? this.dataFimAge : "",
    id: this.state.id,
    tipo_de_imovel: this.state.tipo,
    local: this.state.tipo !== "suspeita" ? this.state.tipo : "",
    tel: this.state.tel,
    lado: this.state.lado.trim(),
    quarteirao: this.state.quarteirao.trim(),
    cpf: this.state.cpf.replace(/\D/g, ""),
    agente: this.state.agente.trim(),
    status: this.state.status !== undefined ? this.state.status : "",
    origem: this.state.origem !== undefined ? this.state.origem : "",
    equipe: this.state.equipe !== undefined ? this.state.equipe : "",
    logradouro: this.state.logradouro.trim(),
    numero: this.state.numero,
    bairro: this.state.bairro ? this.state.bairro.trim() : "",
  };

  

  localStorage.setItem("params", params)
  
  let endpoint = this.state.tipo === "suspeita" ? "os" : "os/solicitacao";

  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map((k) => esc(k) + "=" + esc(params[k]))
    .join("&");

  let host = this.state.api + `/${endpoint}?status=`;

  let path =
    this.state.statusOS === "todos"
      ? "&" + query
      : this.state.statusOS + "&" + query;

  const response = await fetch(host + path, {
    headers: {
      Authorization: localStorage.getItem("agToken"),
    },
  });

  const body = await response.json();

  let bytes = new Blob([JSON.stringify(body)]).size;
  let megabytes = bytes / (1024 * 1024);

  if (body.length > 0) {
    if (megabytes < 4.5) {
      this.setState({
        os: body,
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Sua pesquisa trouxe " +
            body.length +
            " resultados ultrapassando o limite de consultas, por isso serão apresentados apenas os 1000 primeiros.",
          type: "warning",
        },
      });
      this.setState({
        os: body.slice(0, 1000),
        requesting: false,
      });
      return body.slice(0, 1000);
    }
  } else {
    this.setState({
      swal: {
        show: true,
        title: "Atenção",
        text: "Não foram encontrados chamados para os parâmetros informados.",
        type: "error",
      },
      requesting: false,
    });
  }

  if (this.state.os.length > 0) {
    // this.props.history.push("/gerenciarchamadosdetalhe", {
    //   os: this.state.os
    // });
  }

  return body;
};



  fetchOs = async () => {
    let params = {
      data_abertura_inicio: this.dataIni !== undefined ? this.dataIni : "",
      data_abertura_fim: this.dataFim !== undefined ? this.dataFim : "",
      agendamento_inicio: this.dataIniAge !== undefined ? this.dataIniAge : "",
      agendamento_fim: this.dataFimAge !== undefined ? this.dataFimAge : "",
      id: this.state.id,
      tipo_de_imovel: this.state.tipo,
      local: this.state.tipo !== "suspeita" ? this.state.tipo : "",
      tel: this.state.tel,
      lado: this.state.lado.trim(),
      quarteirao: this.state.quarteirao.trim(),
      cpf: this.state.cpf.replace(/\D/g, ""),
      agente: this.state.agente.trim(),
      status: this.state.status !== undefined ? this.state.status : "",
      origem: this.state.origem !== undefined ? this.state.origem : "",
      equipe: this.state.equipe !== undefined ? this.state.equipe : "",
      logradouro: this.state.logradouro.trim(),
      numero: this.state.numero,
      bairro: this.state.bairro ? this.state.bairro.trim() : "",
    };

    

    
    localStorage.setItem("params", JSON.stringify(params));

    let endpoint = this.state.tipo === "suspeita" ? "os" : "os/solicitacao";

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let host = this.state.api + `/${endpoint}?status=`;

    let path =
      this.state.statusOS === "todos"
        ? "&" + query
        : this.state.statusOS + "&" + query;

    const response = await fetch(host + path, {
      headers: {
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    let bytes = new Blob([JSON.stringify(body)]).size;
    let megabytes = bytes / (1024 * 1024);

    if (body.length > 0) {
      if (megabytes < 40.5) {
        this.setState({
          os: body,
          requesting: false,
        });
      } else {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text:
              "Sua pesquisa trouxe " +
              body.length +
              " resultados ultrapassando o limite de consultas, por isso serão apresentados apenas os 1000 primeiros.",
            type: "warning",
          },
        });
        this.setState({
          os: body.slice(0, 1000),
          requesting: false,
        });
        return body.slice(0, 1000);
      }
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Não foram encontrados chamados para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }

    if (this.state.os.length > 0) {
      // this.props.history.push("/gerenciarchamadosdetalhe", {
      //   os: this.state.os
      // });
    }
    

    return body;
  };

  

  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });

            if (this.state.isConfirmation && !result.dismiss) {
              this.setState({
                isConfirmation: false,
              });
              this.handleDeleteOs(this.state.os);
            }
          }}
        />
        <Header />

        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
              {/* <Row className="text-right" style={{ paddingBottom:"2%" }}>
                        <Col className="order-xl-1" xl="12">
                          
                          {localStorage.getItem("perm") !== "secom" && (
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={() => {
                                this.props.history.push("/admin/ticket-new");
                              }}
                            >
                              <b>Cadastrar</b><i className="fa fa-arrow-right" aria-hidden="true"></i>
                            </Button>
                          )}
                        </Col>
                      </Row> */}
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Gerenciar Chamados
                      {/* <Button
                        onClick={() => this.MinMax()}
                        color="primary"
                        style={{ marginLeft: "90%", marginTop: -35 }}
                      >
                        {!isShow  ?  (
                          <i className="fa fa-bars" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-times" aria-hidden="true"></i>
                        )}
                      </Button> */}
                    </h6>

                    
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  CPF Cidadão
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-nome"
                                  type="text"
                                  inputMode="numeric"
                                  required
                                  value={this.state.cpf}
                                  onChange={this.handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-id"
                                >
                                  Número O.S
                                </label>

                                <InputNumber
                                  onlydigits
                                  className="form-control"
                                  id="input-id"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 6);
                                  }}
                                  placeholder=""
                                  value={this.state.id}
                                  onChange={(e) =>
                                    this.setState({ id: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Agente
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-agente"
                                  type="text"
                                  value={this.state.agente}
                                  onChange={(e) =>
                                    this.setState({ agente: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Tipo de Solicitação
                                </label>

                                <select
                                  value={this.state.tipo}
                                  onChange={(e) =>
                                    this.setState({
                                      tipo: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                  id="select-tipo"
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  <option value="caixa_dagua">
                                    Caixas d'água
                                  </option>
                                  <option value="piscinas">
                                    Cadastrar Piscina
                                  </option>
                                  <option value="focos">Focos do Mosquito</option>
                                  <option value="imobiliarias">
                                    Imobiliárias
                                  </option>
                                  <option value="imovel_abandonado">
                                    Imóvel abandonado
                                  </option>
                                  <option value="obras">Canteiros de Obra</option>
                                  <option value="peixes">Tratamento Biológico</option>
                                  <option value="recolhimento">
                                    Recolhimento
                                  </option>
                                  <option value="via_publica">
                                    Via Publica
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <h5 style={{ marginTop: 10 }} className="mb-0">
                                  Origem
                                </h5>
                                <select
                                  value={this.state.origem}
                                  onChange={(e) =>
                                    this.setState({ origem: e.target.value })
                                  }
                                  className="form-control "
                                  id="select-tipo"
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  <option value="APLICATIVO">Aplicativo</option>
                                  <option value="drones">Monitoramento</option>
                                  <option value="email">E-mail</option>
                                  <option value="faleconosco">
                                    Fale Conosco
                                  </option>
                                  <option value="municepe">Municipe</option>
                                  <option value="origem_tecnica">
                                    Origem Técnica
                                  </option>
                                  <option value="ouvidoria">Ouvidoria</option>
                                  <option value="sim">SIM</option>
                                  <option value="telefone">Telefone</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                            <FormGroup>
                            {this.state.equipes.length > 0 && (
                              <div>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-dtnascimento"
                                >
                                  Equipe
                                </label>

                                <select
                                  value={this.state.equipe}
                                  onChange={(e) =>
                                    this.setState({
                                      equipe: e.target.value,
                                    })
                                  }
                                  className="form-control "
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>
                                  {this.state.equipes.map((team) => (
                                    <option key={team.nm_equipe} value={team.nm_equipe}>
                                    {team.ds_descricao_equipe.charAt(0).toUpperCase() +
                                      team.ds_descricao_equipe.slice(1).toLowerCase()}
                                  </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </FormGroup>
                              {/* <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Equipe
                              </label>

                              <select
                                value={this.state.equipe}
                                onChange={(e) =>
                                  this.setState({ equipe: e.target.value })
                                }
                                className="form-control "
                                id="select-tipo"
                              >
                                <option value="">selecione...</option>
                                <option value="equipe_01">Equipe 1</option>
                                <option value="equipe_02">Equipe 2</option>
                                <option value="equipe_03">Equipe 3</option>
                                <option value="equipe_04">Equipe 4</option>
                                <option value="equipe_05">Equipe 5</option>
                                <option value="equipe_06">Equipe 6</option>
                                <option value="equipe_07">Equipe 7</option>
                                <option value="equipe_08">Equipe 8</option>
                                <option value="equipe_09">Equipe 9</option>
                                <option value="equipe_10">Equipe 10</option>
                                <option value="equipe_11">Equipe 11</option>
                                <option value="equipe_12">Equipe 12</option>
                                <option value="equipe_13">Equipe 13</option>
                                <option value="equipe_14">Equipe 14</option>
                                <option value="equipe_15">Equipe 15</option>
                                <option value="equipe_16">Equipe 16</option>
                                <option value="equipe_17">Equipe 17</option>
                                <option value="equipe_18">Equipe 18</option>
                                <option value="equipe_19">Equipe 19</option>
                                <option value="equipe_20">Equipe 20</option>
                                <option value="equipe_21">Equipe 21</option>
                                <option value="equipe_22">Equipe 22</option>
                                <option value="equipe_23">Equipe 23</option>
                                <option value="equipe_24">Equipe 24</option>
                                <option value="equipe_25">Equipe 25</option>
                                <option value="equipe_26">Equipe 26</option>
                                <option value="equipe_27">Equipe 27</option>
                                <option value="equipe_28">Equipe 28</option>
                                <option value="equipe_29">Equipe 29</option>
                                <option value="equipe_30">Equipe 30</option>
                                <option value="acoes">Ações</option>
                                <option value="administrativo">Administrativo</option>
                                <option value="app_drone_mob">App Drone Mob</option>
                                <option value="fog">Fog</option>
                                <option value="material">Material</option>
                                <option value="miraporanga">Miraporanga</option>
                                <option value="tapuirama">Tapuirama</option>
                                <option value="mobilizacao">Mobilização</option>
                                <option value="motorista">Motorista</option>
                                <option value="ovitrampas">Ovitrampas</option>
                                <option value="pe_a">PE_A</option>
                                <option value="pe_a_adm">PE_A_ADM</option>
                                <option value="pe_b">PE_B</option>
                                <option value="pe_b_adm">PE_B_ADM</option>
                                <option value="pe_c">PE_C</option>
                                <option value="pe_c_adm">PE_C_ADM</option>
                                <option value="pe_d">PE_D</option>
                                <option value="pe_d_adm">PE_D_ADM</option>
                                <option value="pe_e">PE_E</option>
                                <option value="pe_e_adm">PE_E_ADM</option>
                                <option value="pe_f">PE_F</option>
                                <option value="pe_f_adm">PE_F_ADM</option>
                                <option value="pe_g">PE_G</option>
                                <option value="pe_g_adm">PE_G_ADM</option>
                                <option value="pedras">Pedras</option>
                                <option value="pneu">Pneu</option>
                                <option value="raio">Raio</option>
                                <option value="supervisao_area">
                                  Supervisão Área
                                </option>
                                <option value="supervisao_turma">
                                  Supervisão Turma
                                </option>
                                <option value="supervisao_geral">
                                  Supervisão Geral
                                </option>
                              </select>
                              </FormGroup> */}
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Telefone Cidadão
                                </label>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  type="text"
                                  name="tel"
                                  id="tel"
                                  inputMode="numeric"
                                  className="form-control"
                                  value={this.state.tel}
                                  onChange={(e) =>
                                    this.setState({ tel: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Data Inicial Chamado
                                </label>
                                <InputMask
                                  mask="99/99/9999"
                                  type="text"
                                  name="data_abertura_inicio"
                                  inputMode="numeric"
                                  id="data_abertura_inicio"
                                  className="form-control"
                                  value={this.state.data_abertura_inicio}
                                  onChange={(e) =>
                                    this.setState({
                                      data_abertura_inicio: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Data Final Chamado
                                </label>

                                <InputMask
                                  mask="99/99/9999"
                                  type="text"
                                  name="data_abertura_fim"
                                  id="data_abertura_fim"
                                  inputMode="numeric"
                                  className="form-control"
                                  value={this.state.data_abertura_fim}
                                  onChange={(e) =>
                                    this.setState({
                                      data_abertura_fim: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Data Inicial Agendamento
                                </label>

                                <InputMask
                                  mask="99/99/9999"
                                  type="text"
                                  name="agendamento_inicio"
                                  id="agendamento_inicio"
                                  inputMode="numeric"
                                  className="form-control"
                                  value={this.state.agendamento_inicio}
                                  onChange={(e) =>
                                    this.setState({
                                      agendamento_inicio: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Data Final Agendamento
                                </label>

                                <InputMask
                                  mask="99/99/9999"
                                  type="text"
                                  name="agendamento_fim"
                                  id="agendamento_fim"
                                  inputMode="numeric"
                                  className="form-control"
                                  value={this.state.agendamento_fim}
                                  onChange={(e) =>
                                    this.setState({
                                      agendamento_fim: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Status Chamado
                                </label>

                                <select
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({ status: e.target.value })
                                  }
                                  className="form-control "
                                  id="select-tipo"
                                >
                                  <option defaultValue="" value="">
                                    {" "}
                                    Selecione...{" "}
                                  </option>

                                  <option value="aberto">Aberto</option>
                                  <option value="andamento">Andamento</option>
                                  <option value="fechado">Fechado</option>
                                  <option value="morador_aus">Morador Ausente</option>
                                  <option value="recusa">Recusa</option>
                                  <option value="vistoria_r">Vistoria Realizada</option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-bairro"
                                >
                                  Bairro
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-lado"
                                  type="text"
                                  maxLength="30"
                                  value={this.state.bairro}
                                  onChange={(e) =>
                                    this.setState({ bairro: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-quarteirao"
                                >
                                  Quarteirão
                                </label>

                                <InputNumber
                                  onlydigits
                                  className="form-control"
                                  id="input-quarteirao"
                                  inputMode="numeric"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 4);
                                  }}
                                  placeholder=""
                                  value={this.state.quarteirao}
                                  onChange={(e) =>
                                    this.setState({
                                      quarteirao: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-lado"
                                >
                                  Lado
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-lado"
                                  type="text"
                                  maxLength="4"
                                  value={this.state.lado}
                                  onChange={(e) =>
                                    this.setState({ lado: e.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-logradouro"
                                >
                                  Logradouro
                                </label>

                                <Input
                                  className="form-control-alternative"
                                  id="input-logradouro"
                                  type="text"
                                  value={this.state.logradouro}
                                  onChange={(e) =>
                                    this.setState({
                                      logradouro: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-numero"
                            >
                              Número
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-numero"
                              type="text"
                              required
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                          </Row>
                        </div>
                    

                    <div className="text-center">
                      <Row>
                        <Col className="order-xl-1" xl="12">
                          <Button
                            className="mt-4 btn-zoo-alt"
                            color="primary"
                            type="button"
                            onClick={this.handleSubmit}
                          >
                            <b>Pesquisar</b>
                          </Button>
                         
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.response && !this.state.requesting && (
           <Row className="text-left" style={{ paddingBottom:"2%" }}>
                        <Col className="order-xl-1" xl="12">

                <ExcelFile>
                <ExcelSheet data={this.state.response} name="OrdensServiço">
                <ExcelColumn label="O.S" 
                value={(row) => " "+row.id}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                <ExcelColumn
                    label="Endereco"
                    value={(rowData) => rowData.endereco ? rowData.endereco.logradouro : "-" }
                     style={{whiteSpace: 'nowrap', wrapText: true }}
                    width={150} 
                    maxWidth={150}
                  />
                  <ExcelColumn
                    label="Origem"
                    value={(row) => {
                      const origemValue = row.origem;
                      let displayedText = "";

                      switch (origemValue) {
                        case "drones":
                          displayedText = "Monitoramento";
                          break;
                        case "email":
                          displayedText = "E-mail";
                          break;
                        case "faleconosco":
                          displayedText = "Fale Conosco";
                          break;
                        case "municepe":
                          displayedText = "Munícipe";
                          break;
                        case "origem_tecnica":
                          displayedText = "Origem Técnica";
                          break;
                        case "ouvidoria":
                          displayedText = "Ouvidoria";
                          break;
                        case "sim":
                          displayedText = "SIM";
                          break;
                        case "telefone":
                          displayedText = "Telefone";
                          break;
                        default:
                          displayedText = "-";
                          break;
                      }

                      return displayedText;
                    }}
                  />
                  <ExcelColumn label="Nr" 
                value={(row) => " "+row.numero}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                  <ExcelColumn label="Bairro" 
                value={(row) => row.bairro}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                  <ExcelColumn 
                  label="Quarteirão" 
                  value={(row) => row.quarteirao ? `${row.quarteirao.toString()}` : ""}
                  cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                />
                  <ExcelColumn label="Lado" 
                value={(row) => row.lado}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                 <ExcelColumn label="status" 
                value={(row) => row.status}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                 <ExcelColumn label="data_abertura" 
                value={(row) => row.data_abertura ? moment(moment(row.data_abertura)).format("DD/MM/YYYY") :""}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 />
                 {/* <ExcelColumn label="agendamento" 
                value={(row) => row.agendamento ? moment(moment(row.agendamento)).format("DD/MM/YYYY") : "N/A"}
                cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
                 /> */}
                  <ExcelColumn
              label="tipo"
              value={(row) => {
                if (row.tipo === "solicitacao") {
                  const selectedValue = row.tipo_de_imovel;
                  let displayedText = "";

                  switch (selectedValue) {
                    case "via_publica":
                      displayedText = "Via Pública";
                      break;
                    case "recolhimento":
                      displayedText = "Recolhimento";
                      break;
                    case "peixes":
                      displayedText = "Tratamento Biológico";
                      break;
                    case "obras":
                      displayedText = "Canteiros de Obra";
                      break;
                    case "imovel_abandonado":
                      displayedText = "Imóvel Abandonado";
                      break;
                    case "imobiliarias":
                      displayedText = "Imobiliárias";
                      break;
                    case "focos":
                      displayedText = "Focos do Mosquito";
                      break;
                    case "piscinas":
                      displayedText = "Cadastrar Piscina";
                      break;
                    case "caixa_dagua":
                      displayedText = "Caixas d'Água";
                      break;
                    default:
                      break;
                  }

                  return Util.capitalize(displayedText);
                } else {
                  return Util.capitalize(row.tipo);
                }
              }}
              cell={(props) => <span style={{ textAlign: 'left' }}>{props.value}</span>}
            />
                 
                  
                  
                  
                 
                </ExcelSheet>
           
              </ExcelFile>
                        
                        </Col>
                      </Row>
                      )}
          {this.state.response && !this.state.requesting && (
            <DataTable
              noHeader={true}
              pagination={true}
              striped={true}
              columns={this.columns}
              sortable={true}
              responsive={true}
              highlightOnHover={true}
              data={this.state.response}
              noDataComponent="Nenhum registro encontrado."
              paginationComponentOptions={this.defaultComponentOptions}
              customStyles={this.customStyles}
              paginationDefaultPage={this.state.currentPage}
              onChangePage={this.handlePageChange}
              
            />
          )}
          {this.state.requesting && (
            <div className="text-center">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                color="primary"
              />
            </div>
          )}
        </Container>
      </>
    );
  }
}
