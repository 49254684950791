import Index from "views/Index.js";
import Profile from "views/Profile.js";
import Register from "views/Register.js";
import ForgotPassword from "views/ForgotPassword.js";
import ChangePassword from "views/ChangePassword.js";
import Login from "views/Login";
import Password from "views/password/Password.js";


/* Empresa */

import EmpresaCad from "views/empresa/EmpresaCad";
import EmpresaEdit from "views/empresa/EmpresaEdit";
import EmpresaList from "views/empresa/EmpresaList";


/* Agent */
import AgentList from "views/agent/AgentList";
import AgentEdit from "views/agent/AgentEdit";

/* Equipes */
import EquipeList from "views/equipes/EquipeList";
import EquipeEdit from "views/equipes/EquipeEdit";

/* Citizen */

import CitizenList from "views/citizen/CitizenList";
import CitizenEdit from "views/citizen/CitizenEdit";

/* Pool */
import PoolList from "views/pool/PoolList";
import PoolEdit from "views/pool/PoolEdit";
import PoolEditar from "views/pool/PoolEditar";

/* Drone */

import DroneList from "views/drones/DroneList";
import DroneEdit from "views/drones/DroneEdit";

/* TireShop */
import TireShopList from "views/tireShop/TireShopList";
import TireshopEdit from "views/tireShop/TireshopEdit";
import TireshopEditar from "views/tireShop/TireshopEditar";

/* Ticket */

import TicketList from "views/ticket/TicketList";
import TicketEdit from "views/ticket/TicketEdit";
import TicketNew from "views/ticket/TicketNew";

/* Owner */
import OwnerList from "views/owner/OwnerList";

/* Feed */

import FeedList from "views/feed/FeedList";
import FeedEdit from "views/feed/FeedEdit";

/* Info */

import PhoneList from "views/info/PhoneList";
import ContactList from "views/info/ContactList";
import Normatives from "views/info/Normatives";
import BusList from "views/info/BusList";
import GovEmployeeList from "views/info/GovEmployeeList";

/*Digital Sketch */
import DigitalSketchList from "views/digitalSketch/DigitalSketchList";
import DigitalSketchEdit from "views/digitalSketch/DigitalSketchEdit";

//Neighboorhood

import NeighborhoodList from "views/neighborhoods/NeighborhoodList";
import NeighborhoodEdit from "views/neighborhoods/NeighborhoodEdit";



/*Property */
import PropertyList from "views/property/PropertyList";
import PropertyEdit from "views/property/PropertyEdit";
import PropertyEditar from "views/property/PropertyEditar";


/*Bio Control */
import BioControlList from "views/bioControl/BioControlList";
import BioControlEdit from "views/bioControl/BioControlEdit";
import BioControlEditar from "views/bioControl/BioControlEditar";

/*Strategic Point */
import StrategicPointList from "views/strategicPoint/StrategicPointList";
import StrategicPointEdit from "views/strategicPoint/StrategicPointEdit";
import StrategicPointEditar from "views/strategicPoint/StrategicPointEditar";

/* Itinerario */

import ItineraryList from "views/itinerary/ItineraryList";
import ItineraryEdit from "views/itinerary/ItineraryEdit";
import ItineraryNew from "views/itinerary/ItineraryNew";

/*Ovitramp */
import OvitrampList from "views/ovitramp/OvitrampList";
import OvitrampEdit from "views/ovitramp/OvitrampEdit";
import OvitrampMap from "views/ovitramp/OvitrampMap";
import OvitrampLabEdit from "views/ovitramplab/OvitrampLabEdit";
import OvitrampLabList from "views/ovitramplab/OvitrampLabList";

// mov

import OvitrampMovEdit from "views/ovitrampmov/OvitrampMovEdit";
import OvitrampMovEditSingle from "views/ovitrampmov/OvitrampMovEditSingle";
import OvitrampMovList from "views/ovitrampmov/OvitrampMovList";

/* Graphics */
import ShowGraphics from "views/graphics/ShowGraphics"; 

/* UBV */
import UbvList from "views/ubv/UbvList";
import UbvEdit from "views/ubv/UbvEdit";

import LogoChamados from "assets/img/icons/sidebar/noticias.svg";


//cadastro


import CadastroList from "views/cadastro/CadastroList";
import CadastroCreate from "views/cadastro/CadastroCreate";

//Ronda

import RondaEdit from "views/ronda/RondaEdit";
import RondaEditar from "views/ronda/RondaEditar";



import {
  faCarCrash,
  faSearch,
  faNewspaper,
  faKey,
  faUserTie,
  faChalkboardTeacher,
  faInfoCircle,
  faHome,
  faFish,
  faCompressArrowsAlt,
  faFill,
  faCrosshairs,
  faMapSigns,
  faLayerGroup,
  faUserTag,
  faTruckLoading,
  faBriefcase,
  faUsersCog,
  faBook,
  faBuilding
} from "@fortawesome/free-solid-svg-icons";

var routes = [
  {
    path: "/index",
    name: "Home",
    icon: "ni ni-tv-2 txt-main-color",
    component: Index,
    layout: "/admin",
    isMenu: false,
    permissions: ["coordenador", "agente", "secom", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
  },
  // {
  //   path: "/disease",
  //   name: "AEDES & DOENÇAS",
  //   icon: faVirus,
  //   component: DiseaseList,
  //   layout: "/admin",
  //   isMenu: true,
  //   permissions: ["agente", "coordenador"]
  // },
  // {
  //   path: "/disease-edit",
  //   name: "AEDES & DOENÇAS",
  //   icon: faVirus,
  //   component: DiseaseEdit,
  //   layout: "/admin",
  //   isMenu: false
  // },

  {
    path: "/support",
    name: "CADASTRO",
    icon: faLayerGroup,
    component: Profile,
    layout: "/admin",
    permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    isMenu: true,
    subItem: [
      {
        path: "/cadastroListar",
        name: "PESQUISAR",
        icon: faCarCrash,
        component: CadastroList,
        layout: "/admin",
        isMenu: true,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/cadastroCriar",
        name: "CADASTRAR",
        icon: faCarCrash,
        component: CadastroCreate,
        layout: "/admin",
        isMenu: true,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/pool-edit",
        name: "CADASTRO - RESERVATÓRIOS",
        icon: faCarCrash,
        component: PoolEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/pool-cad",
        name: "CADASTRO - RESERVATÓRIOS",
        icon: faCarCrash,
        component: PoolEditar,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      // {
      //   path: "/registred-property",
      //   name: "GRANDES ÁREAS",
      //   component: PropertyList,
      //   layout: "/admin",
      //   isMenu: true,
      //   permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      // },
      {
        path: "/registred-property-edit",
        name: "GRANDES ÁREAS",
        component: PropertyEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/registred-property-cad",
        name: "GRANDES ÁREAS",
        component: PropertyEditar,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/abandoned-property-cad",
        name: "IMÓVEIS EM ABANDONO",
        component: PropertyEditar,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      // {
      //   path: "/abandoned-property",
      //   name: "IMÓVEIS EM ABANDONO",
      //   component: PropertyList,
      //   layout: "/admin",
      //   isMenu: true,
      //   permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      // },
      {
        path: "/abandoned-property-edit",
        name: "IMÓVEIS EM ABANDONO",
        component: PropertyEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },

      {
        path: "/ronda-edit",
        name: "RONDAS ESCOLARES",
        component: RondaEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/ronda-cad",
        name: "RONDAS ESCOLARES",
        component: RondaEditar,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      // {
      //   path: "/biocontrol",
      //   name: "CONTROLE BIOLÓGICO",
      //   icon: faFish,
      //   component: BioControlList,
      //   layout: "/admin",
      //   isMenu: true,
      //   permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      // },
      {
        path: "/biocontrol-edit",
        name: "CADASTROS CONTROLE BIOLÓGICO",
        icon: faFish,
        component: BioControlEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/biocontrol-cad",
        name: "CADASTROS CONTROLE BIOLÓGICO",
        icon: faFish,
        component: BioControlEditar,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      // {
      //   path: "/tireshop",
      //   name: "ECOPNEUMÁTICOS",
      //   icon: faCarCrash,
      //   component: TireShopList,
      //   layout: "/admin",
      //   isMenu: true,
      //   permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      // },
      {
        path: "/tireshop-edit",
        name: "ECOPNEUMÁTICOS",
        icon: faCarCrash,
        component: TireshopEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/tireshop-cad",
        name: "ECOPNEUMÁTICOS",
        icon: faCarCrash,
        component: TireshopEditar,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      // {
      //   path: "/strategicpoint",
      //   name: "PONTOS ESTRATÉGICOS",
      //   icon: faCompressArrowsAlt,
      //   component: StrategicPointList,
      //   layout: "/admin",
      //   isMenu: true,
      //   permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      // },
      {
        path: "/strategicpoint-edit",
        name: "CADASTROS PONTO ESTRATETICO",
        icon: faFish,
        component: StrategicPointEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/strategicpoint-cad",
        name: "CADASTROS PONTO ESTRATETICO",
        icon: faFish,
        component: StrategicPointEditar,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      }
    ]
  },

  // {
  //   path: "/support",
  //   name: "CADASTRO",
  //   icon: faLayerGroup,
  //   component: Profile,
  //   layout: "/admin",
  //   permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
  //   isMenu: true,
  //   subItem: [
  //     {
  //       path: "/pool",
  //       name: "RESERVATÓRIOS",
  //       icon: faCarCrash,
  //       component: PoolList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/pool-edit",
  //       name: "CADASTRO - RESERVATÓRIOS",
  //       icon: faCarCrash,
  //       component: PoolEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/registred-property",
  //       name: "GRANDES ÁREAS",
  //       component: PropertyList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/registred-property-edit",
  //       name: "GRANDES ÁREAS",
  //       component: PropertyEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/abandoned-property",
  //       name: "IMÓVEIS EM ABANDONO",
  //       component: PropertyList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/abandoned-property-edit",
  //       name: "IMÓVEIS EM ABANDONO",
  //       component: PropertyEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/biocontrol",
  //       name: "CONTROLE BIOLÓGICO",
  //       icon: faFish,
  //       component: BioControlList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/biocontrol-edit",
  //       name: "CADASTROS CONTROLE BIOLÓGICO",
  //       icon: faFish,
  //       component: BioControlEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/tireshop",
  //       name: "ECOPNEUMÁTICOS",
  //       icon: faCarCrash,
  //       component: TireShopList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/tireshop-edit",
  //       name: "ECOPNEUMÁTICOS",
  //       icon: faCarCrash,
  //       component: TireshopEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/strategicpoint",
  //       name: "PONTOS ESTRATÉGICOS",
  //       icon: faCompressArrowsAlt,
  //       component: StrategicPointList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/strategicpoint-edit",
  //       name: "CADASTROS PONTO ESTRATETICO",
  //       icon: faFish,
  //       component: StrategicPointEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     }
  //   ]
  // },
  // {
  //   path: "/specials",
  //   name: "AÇÕES ESPECIAIS",
  //   icon: faLayerGroup,
  //   component: Profile,
  //   layout: "/admin",
  //   permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
  //   isMenu: true,
  //   subItem: [
  //     {
  //       path: "/biocontrol",
  //       name: "CONTROLE BIOLÓGICO",
  //       icon: faFish,
  //       component: BioControlList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/biocontrol-edit",
  //       name: "CADASTROS CONTROLE BIOLÓGICO",
  //       icon: faFish,
  //       component: BioControlEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/tireshop",
  //       name: "ECOPNEUMÁTICOS",
  //       icon: faCarCrash,
  //       component: TireShopList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/tireshop-edit",
  //       name: "ECOPNEUMÁTICOS",
  //       icon: faCarCrash,
  //       component: TireshopEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/strategicpoint",
  //       name: "PONTOS ESTRATÉGICOS",
  //       icon: faCompressArrowsAlt,
  //       component: StrategicPointList,
  //       layout: "/admin",
  //       isMenu: true,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     },
  //     {
  //       path: "/strategicpoint-edit",
  //       name: "CADASTROS PONTO ESTRATETICO",
  //       icon: faFish,
  //       component: StrategicPointEdit,
  //       layout: "/admin",
  //       isMenu: false,
  //       permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
  //     }
  //   ]
  // },
  {
    path: "/bloqueios",
    name: "BLOQUEIOS",
    icon: faTruckLoading,
    component: Profile,
    layout: "/admin",
    permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    isMenu: true,
    subItem: [
      {
        path: "/ubv",
        name: "CONSULTA BLOQUEIO",
        icon: faCarCrash,
        component: UbvList,
        layout: "/admin",
        isMenu: true,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/ubv-edit",
        name: "CONSULTA BLOQUEIO",
        icon: faCarCrash,
        component: UbvEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
    ]
  },
  {
    path: "/field",
    name: "CAMPO",
    icon: faBriefcase,
    component: Profile,
    layout: "/admin",
    permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    isMenu: true,
    subItem: [
      {
        path: "/digital-sketch",
        name: "CROQUIS DE LOCALIDADES",
        component: DigitalSketchList,
        layout: "/admin",
        isMenu: true
      },
      
      {
        path: "/digital-sketch-edit",
        name: "CROQUIS DE LOCALIDADES",
        component: DigitalSketchEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["coordenador", "operador", "operador"]
      },
      {
        path: "/itinerary",
        name: "ITINERÁRIO",
        icon: faMapSigns,
        component: ItineraryList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
      },
      {
        path: "/itinerary-edit",
        name: "Editar Itinerário",
        icon: faMapSigns,
        component: ItineraryEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["coordenador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
      },
      {
        path: "/itinerary-new",
        name: "Cadastro Itinerário",
        icon: faMapSigns,
        component: ItineraryNew,
        layout: "/admin",
        isMenu: false,
        permissions: ["coordenador", "agente","operador"]
      },
      {
        path: "/neighboorhood",
        name: "LOCALIDADES",
        component: NeighborhoodList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador", "agente"]
      },
      {
        path: "/neighboorhood-edit",
        name: "LOCALIDADES",
        component: NeighborhoodEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["coordenador", "agente"]
      },
    ]
  },
  {
    path: "/coordenation",
    name: "COORDENAÇÃO",
    icon: faUsersCog,
    component: Profile,
    layout: "/admin",
    permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    isMenu: true,
    subItem: [
      {
        path: "/citizen",
        name: "CIDADÃO",
        icon: faSearch,
        component: CitizenList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador"]
      },
      {
        path: "/citizen-edit",
        name: "CIDADÃO",
        icon: faSearch,
        component: CitizenEdit,
        layout: "/admin",
        isMenu: false
      },
      {
        path: "/owner",
        name: "PROPRIETÁRIO DE IMÓVEL",
        icon: faUserTag,
        component: OwnerList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador"]
      },
      {
        path: "/graficos",
        name: "MAPA DE CALOR - A90",
        icon: faFill,
        component: ShowGraphics,
        layout: "/admin",
        isMenu: true,
         permissions: ["coordenador"]
       }
    ]
  },
  {
    path: "/user-profile",
    name: "INFORMAÇÕES",
    icon: faInfoCircle,
    component: Profile,
    layout: "/admin",
    permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    isMenu: true,
    subItem: [
      {
        path: "/contact-list",
        name: "FALA SERVIDOR",
        icon: LogoChamados,
        component: ContactList,
        layout: "/admin",
        isMenu: true
      },
      {
        path: "/bus-list",
        name: "LINHAS DE ÔNIBUS",
        icon: LogoChamados,
        component: BusList,
        layout: "/admin",
        isMenu: true
      },
      {
        path: "/feed",
        name: "NOTÍCIAS",
        icon: faNewspaper,
        component: FeedList,
        layout: "/admin",
        isMenu: true,
        permissions: ["secom", "coordenador", "agente"]
      },
      {
        path: "/feed-edit",
        name: "NOTÍCIAS",
        icon: faNewspaper,
        component: FeedEdit,
        layout: "/admin",
        isMenu: false
      },
      {
        path: "/govemployee-list",
        name: "RH ONLINE",
        icon: LogoChamados,
        component: GovEmployeeList,
        layout: "/admin",
        isMenu: true
      },
      {
        path: "/phone-list",
        name: "TELEFONES ÚTEIS",
        icon: LogoChamados,
        component: PhoneList,
        layout: "/admin",
        isMenu: true
      }
    ]
  },
  {
    path: "/monitoring",
    name: "MONITORAMENTO",
    icon: faCrosshairs,
    component: Profile,
    layout: "/admin",
    permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    isMenu: true,
    subItem: [
      {
        path: "/drone",
        name: "DRONES",
        icon: faCrosshairs,
        component: DroneList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
      },
      {
        path: "/drone-edit",
        name: "CADASTROS Drone",
        icon: faCrosshairs,
        component: DroneEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
      },
      {
        path: "/laboratorio",
        name: "OVITRAMPAS",
        icon: faFill,
        component: OvitrampMovList,
        layout: "/admin",
        isMenu: true,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"],
        subsubItem: [
          {
            path: "/ovitramp",
            name: "CADASTRO",
            icon: faFill,
            component: OvitrampList,
            layout: "/admin",
            isMenu: true,
          },
          {
            path: "/laboratorio",
            name: "MOVIMENTO",
            icon: faFill,
            component: OvitrampList,
            layout: "/admin",
            isMenu: true
          },
          {
            path: "/mapa",
            name: "MAPA - GERAL DE OVITRAMPAS",
            icon: faFill,
            component: OvitrampMap,
            layout: "/admin",
            isMenu: true
          },
          
        ]
      },
      {
        path: "/laboratorio-edit",
        name: "MONITORAMENTO OVITRAMPAS",
        icon: faFill,
        component: OvitrampMovEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },

      {
        path: "/lab",
        name: "OVITRAMPAS",
        icon: faFill,
        component: OvitrampMovEditSingle,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      
      {
        path: "/ovitramp",
        name: "OVITRAMPAS CADASTRO",
        icon: faFill,
        component: OvitrampList,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      },
      {
        path: "/ovitramp-edit",
        name: "CADASTROS OVITRAMPAS",
        icon: faFill,
        component: OvitrampEdit,
        layout: "/admin",
        isMenu: false,
        permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual", "operador"]
      }
    ]
  },
  {
    path: "/regulament",
    name: "REGULAMENTAÇÃO",
    icon: faBook,
    component: Profile,
    layout: "/admin",
    permissions: ["coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    isMenu: true,
    subItem: [
      {
        path: "/normative-list",
        name: "NORMAS TÉCNICAS (POP)",
        icon: LogoChamados,
        component: Normatives,
        layout: "/admin",
        isMenu: true
      },
    ]
  },
  {
    path: "/ticket",
    name: "ORDENS DE SERVIÇO",
    icon: faChalkboardTeacher,
    component: TicketList,
    layout: "/admin",
    isMenu: true,
    permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"],
    subItem: [
      {
        path: "/ticket",
        name: "PESQUISAR O.S.",
        icon: LogoChamados,
        component: TicketList,
        layout: "/admin",
        isMenu: true
      },
      {
        path: "/ticket-new",
        name: "CADASTRAR O.S.",
        icon: LogoChamados,
        component: TicketNew,
        layout: "/admin",
        isMenu: true,
        // permissions: ["coordenador", "agente", "Operador"]
      },
    ]
  },
  {
    path: "/ticket-edit",
    name: "CHAMADOS",
    icon: faChalkboardTeacher,
    component: TicketEdit,
    layout: "/admin",
    isMenu: false,
    permissions: ["agente", "coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
  },
  {
    path: "/ticket-new",
    name: "CHAMADOS",
    icon: faChalkboardTeacher,
    component: TicketNew,
    layout: "/admin",
    isMenu: false,
    permissions: ["coordenador", "agente"]
  },

  {
    path: "/agent",
    name: "SERVIDOR",
    icon: faUserTie,
    component: AgentList,
    layout: "/admin",
    isMenu: true,
    permissions: ["coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]  ,
    subItem: [
      {
        path: "/agent",
        name: "SERVIDOR",
        icon: faUserTie,
        component: AgentList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
      },
      {
        path: "/equipelist",
        name: "EQUIPES",
        icon: LogoChamados,
        component: EquipeList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador"]
      }
      
    ]
  },

  {
    path: "/equipedit",
    name: "CADASTROS EQUIPES",
    icon: faUserTie,
    component: EquipeEdit,
    layout: "/admin",
    isMenu: false
  },




  // {
  //   path: "/agent",
  //   name: "SERVIDOR",
  //   icon: faUserTie,
  //   component: AgentList,
  //   layout: "/admin",
  //   isMenu: true,
  //   permissions: ["coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
  // },
  // {
  //   path: "/agent",
  //   name: "SERVIDOR",
  //   icon: faUserTie,
  //   component: AgentList,
  //   layout: "/admin",
  //   isMenu: true,
  //   permissions: ["coordenador", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
  // },
  {
    path: "/agent-edit",
    name: "CADASTROS AGENTE",
    icon: faUserTie,
    component: AgentEdit,
    layout: "/admin",
    isMenu: false
  },








  {
    path: "/empresalist",
    name: "EMPRESAS",
    icon: faBuilding,
    component: EmpresaList,
    layout: "/admin",
    isMenu: true,
    permissions: ["coordenador", "supervisao_area"] ,
    subItem: [
      {
        path: "/empresadit",
        name: "EDITAR EMPRESA",
        icon: faBuilding,
        component: EmpresaEdit,
        layout: "/admin",
        isMenu: false
      },
   
      {
        path: "/empresalist",
        name: "LISTAR",
        icon: LogoChamados,
        component: EmpresaList,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador"]
      },

      
      {
        path: "/empresacad",
        name: "CADASTRAR",
        icon: faBuilding,
        component: EmpresaCad,
        layout: "/admin",
        isMenu: true,
        permissions: ["coordenador", "supervisao_area"]
      },
    
    
      
    ]
  },










  {
    path: "/password",
    name: "ALTERAR SENHA",
    icon: faKey,
    component: Password,
    layout: "/admin",
    isMenu: false,
    permissions: ["secom", "coordenador", "agente", "operador", "supervisao_area", "supervisao_turma" , "supervisaoentual"]
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-circle-08 text-pink",
    component: ForgotPassword,
    layout: "/auth"
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-circle-08 text-pink",
    component: ChangePassword,
    layout: "/auth"
  },
  {
    path: "/profile",
    name: "PERFIL",
    icon: faHome,
    component: Profile,
    layout: "/admin",
    isMenu: false,

  },
  {
    path: "/mapa",
    name: "MAPA - GERAL DE OVITRAMPAS",
    icon: faFill,
    component: OvitrampMap,
    layout: "/admin",
    isMenu: false
  },

];
export default routes;





