import React, { useState, useEffect } from "react";

import MapsLeaflet from "../maps/maps_leaflet";
import Api from "shared/resources/Api";
import { DataMap, calcZone, getType } from "../maps/dataMap";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody
} from "reactstrap";
import "react-image-lightbox/style.css";

const OvitrampMap = () => {

  const callApi = async () => {
   
    let response = await fetch(Api.baseURL + '/ovitrampa/posicao', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('agToken'),
      },
    });
    let body = await response.json();
    setDadosMapa(body.data);

    response = await fetch(Api.baseURL + '/ovitrampa/setor', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('agToken'),
      },
    });
    body = await response.json();
    

    setSetores(body.data);


    return true;
  }


  const [showMap, setShowMap] = useState(false);
  const [dadosMapa, setDadosMapa] = useState([]);
  const [setores, setSetores] = useState([]);


  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      callApi();
    }, 20000);

    return () => clearInterval(interval);
  }, []);




  return (
    <>
      {showMap && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            zIndex: 1000,
            padding: '20px',
            borderRadius: '5px',
            border: '5px solid rgb(24, 144, 255)',
            borderBottom: 'none'
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setShowMap(false);
            }
          }}
          tabIndex={0}
        >
          <button
            className="btn btn-danger mb-3"
            onClick={() => setShowMap(false)}
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              cursor: 'pointer',
              zIndex: 1001 // Garante que o botão fique acima do conteúdo
            }}
            type="button" // Explicita que é um botão clicável
          >
            Fechar
          </button>
          <div style={{width: "100%", height: "100%"}}>
              <MapsLeaflet dados={dadosMapa} setor={setores} />            
          </div>
 

        </div>
      )}

      <Container className="mb-7 mt-5" fluid>
      <div className="mb-3" style={{height: "6vw"}}></div>

        <div className="mb-3 d-flex flex-column align-items-start" style={{height: "60px",marginBottom: "20px",top:"180px"}}>
          <div className="d-flex align-items-start">
            <button
              className="btn btn-primary mb-3"
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setShowMap(false);
                }
              }}
              onClick={() => setShowMap(!showMap)}
            >Mostrar mapa</button>
          </div>
        </div>
        <div className="mb-3" style={{height: "15vw"}}></div>

    

      </Container>
    </>
  );
};

export default OvitrampMap;