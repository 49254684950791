import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Spinner,
    Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";


import SweetAlert2 from "react-sweetalert2";
import Util from "shared/services/Util";
import Api from "shared/resources/Api";
import Header from "components/Headers/Header.js";
import moment from "moment";
import ImageUploader from "react-images-upload";
import api from "shared/resources/Api";
import InputMask from "react-input-mask";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// import {agentes } from "shared/services/PlacesService";

import gconf from "shared/resources/Gconf";
export default class OvitrampMovEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cep: "",
            nome: "",
            tel: "",
            logradouro: "",
            numero: "",
            bairro: "",
            complemento: "",
            lat: -18.90971,
            lon: -48.261921,
            api: Api.baseURL,
            isMapOk: false,
            isEdicao: false,
            formTitle: "CADASTRAR",
            neighborhood: [],
            sectors: [],
            selectedSector: null,
            selectedNeighborhood: "",
            lado: null,
            codigomovimento: null,
            quarteirao: null,
            situacao: "",
            codigo: null,
            control: [],
            agents: [],
            isLoading: true,
            agente: null,
            selectedOvitramp: [],
            selectedFoto: [],
            pictures: [],
            selectedData: [],
            selectedAedes: [],
            selectedAlbobictus: [],
            selectedLarvas: [],
            selectedOutros: [],
            selectedAgente: [],
            selectedButtonLabel: [],
            selectedClassLabel: [],
            isSuccess: false,
            file: null,
            foto: null,
            isOpen: false,
            setIsOpen: false,
            imageSrc: null,
            idmovimento: null,
        };

        this.handleSave = this.handleSave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    componentWillMount() {
        var originLab = false;

        if (
            this.props.location != null &&
            this.props.location.state != null &&
            this.props.location.state.ovitramp != null
        ) {

            if(this.props.location.state.listSource == null)
            {
               originLab = true;
            }
            this.setState(
                {
                    foto: this.props.location.state.ovitramp.media?
                              `${this.props.location.state.ovitramp.media.foto1}?${moment().format('YYYYMMDDHHMMSS')}`
                              :"",
                    ovitramp: originLab ? this.props.location.state.ovitramp.ovitrampa : this.props.location.state.ovitramp,
                    agent: this.props.location.state.os,
                    loading: false,
                    nome: this.props.location.state.ovitramp.nome,
                    lado: this.props.location.state.ovitramp.lado,
                    quarteirao: this.props.location.state.ovitramp.quarteirao,
                    tipo: this.props.location.state.ovitramp.tipo,
                    logradouro: "",
                    idmovimento: this.props.location.state.ovitramp.id,
                   // logradouro: this.props.location.state.ovitramp.logradouro.trim(),
                    situacao: originLab ? this.props.location.state.ovitramp.ovitrampa.situacao : "",
                    numero: this.props.location.state.ovitramp.numero,
                    complemento: this.props.location.state.ovitramp.complemento,
                    isMapOk: true,
                    isEdicao: true,
                    formTitle: "EDITAR",
                    codigo: originLab ? this.props.location.state.ovitramp.oid_ovitrampa : this.props.location.state.ovitramp.id
                },
                () => this.fetchControl()
            );
        }

        // equipes().then((res) => {
        //       if (res) {
        //         this.setState({
        //           equipes: res,
        //         });
        //       }
        //     });

            // agentes().then((res) => {
            //     if (res) {
            //       this.setState({
            //         agents: res,
            //       });
            //     }
            //   });
    }

    fetchControl = async () => {
        let host = this.state.api + "/movimento_ovitrampa?oid_ovitrampa=" + this.state.codigo+"&idlast="+ this.state.idmovimento;


        const response = await fetch(host, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("agToken"),
            },
        });

        var body = await response.json();

        
 
        if (Array.isArray(body.data) && body.data.length > 0) {

            const sortedData = [...body.data].sort((a, b) => {
                // Convert 'ds_semana_movimento' to numbers
                const semanaA = parseInt(a.ds_semana_movimento, 10);
                const semanaB = parseInt(b.ds_semana_movimento, 10);
        
                // If 'ds_semana_movimento' is equal, then sort by 'DT_OVITRAMPA_MOVIMENTO'
                if (semanaA === semanaB) {
                    const dateA = moment(a.DT_OVITRAMPA_MOVIMENTO);
                    const dateB = moment(b.DT_OVITRAMPA_MOVIMENTO);
        
                    // Check if dates are valid
                    if (!dateA.isValid() || !dateB.isValid()) {
                        console.error('Invalid date:', a.DT_OVITRAMPA_MOVIMENTO, b.DT_OVITRAMPA_MOVIMENTO);
                        return 0;
                    }
        
                    // Compare dates if weeks are the same
                    return dateB.valueOf() - dateA.valueOf();
                }
        
                // If weeks are different, compare them numerically
                return semanaB - semanaA;  
            });
        
            // Add `active2: true` if sum of `nr_ovos_eclodidos_lab`, `nr_ovos_inviaveis_lab`, and `nr_ovos_viaveis_lab` is 0
            const updatedData = sortedData.map(item => ({
                ...item,
                active2: (item.nr_ovos_eclodidos_lab + item.nr_ovos_inviaveis_lab + item.nr_ovos_viaveis_lab + item.nr_ovos_outros) === 0
            }));
        
            console.log("Updated Data:", updatedData);
        
            this.setState({
                control: updatedData,
                codigomovimento: updatedData[0].id,
                requesting: false,
            });
        
            return updatedData;
        }
        // } else {
        //     var currentControl = [
        //         {
        //             ovos_aeg: 0,
        //             ovos_alb: 0,
        //             ovos_larva: 0,
        //             data_criacao: moment().format("DD/MM/YYYY"),
        //             active: true,
        //             semana: 1,
        //             ovos_culex: this.state.ovitramp.nr_ovos_outros,
        //             ano: this.state.ovitramp.ano,
        //             id_ovitrampa: this.state.ovitramp.codigo,
        //             codigo_ovitrampa: "",
        //             id: "Novo"
        //         }
        //     ]
        //     this.setState({
        //         control: currentControl,
        //         requesting: false,
        //     });
        // }
        console.log(JSON.stringify(this.state.control))
        // return body;
    };

    handleControlDataArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedData];
        updatedArray[index] = newValue;
        this.setState({
            selectedData: updatedArray,
        });
    };

    handleControlAedesArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedAedes];
        updatedArray[index] = newValue;
        this.setState({
            selectedAedes: updatedArray,
        });
    };

    handleControlAlbobictusArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedAlbobictus];
        updatedArray[index] = newValue;
        this.setState({
            selectedAlbobictus: updatedArray,
        });
    };

    handleControlLarvasArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedLarvas];
        updatedArray[index] = newValue;
        this.setState({
            selectedLarvas: updatedArray,
        });
    };

    handleControlOutrosArray = (index, newValue) => {
        const updatedArray = [...this.state.selectedOutros];
        updatedArray[index] = newValue;
        this.setState({
            selectedOutros: updatedArray,
        });
    };

    handleControlAgente = (index, newValue) => {
        const updatedArray = [...this.state.selectedAgente];
        updatedArray[index] = newValue;
        this.setState({
            selectedAgente: updatedArray,
        });
    };

    toggleModal = (biimagem) => {
        this.setState((prevState) => ({
          isOpen: !prevState.isOpen,
          selectedImage: biimagem || null,
        }));
      };

    handleSaveControl = (index) => {

        if (this.validateControlFields(index)) {
            this.callSaveControl(index);
        }
    };


    callSaveControl = async (index) => {

        //   console.log('save control',this.state.codigo)
        //   const binaryData = this.base64ToBinary(this.state.selectedFoto[index]);
          
        try {
            var form = {
                oid_ovitrampa: this.state.codigo ? this.state.codigo : this.props.location.state.ovitramp.id,
                        // dt_ovitrampa_movimento: moment(
                        //     moment(this.state.selectedData[index], "DD/MM/YYYY")
                        // ).format("YYYY-MM-DDTHH:mm"),
                       // bi_imagem_original: this.props.location.state.listSource != null ? this.state.ovitramp.id : this.state.ovitramp.ovitrampa,
                        
                        nr_ovos_viaveis_lab: (this.state.selectedAedes[index] !== undefined && this.state.selectedAedes[index] !== null && this.state.selectedAedes[index] !== "") ? this.state.selectedAedes[index] : 0,
                        nr_ovos_inviaveis_lab: (this.state.selectedAlbobictus[index] !== undefined && this.state.selectedAlbobictus[index] !== null && this.state.selectedAlbobictus[index] !== "") ? this.state.selectedAlbobictus[index] : 0,
                        nr_ovos_eclodidos_lab: (this.state.selectedLarvas[index] !== undefined && this.state.selectedLarvas[index] !== null && this.state.selectedLarvas[index] !== "") ? this.state.selectedLarvas[index] : 0,
                        nr_ovos_outros: (this.state.selectedOutros[index] !== undefined && this.state.selectedOutros[index] !== null && this.state.selectedOutros[index] !== "") ? this.state.selectedOutros[index] : 0,
                        // ds_semana_movimento: (this.state.control.length).toString(),
                        oid_agente_lab: this.state.selectedAgente[index]?.id || null,
                        // bi_imagem_original: this.state.foto ? this.state.foto : ""
              };

              var data = new FormData();
              data.append("body", JSON.stringify(form));

              if (this.state.file !== null) {
                data.append("bi_imagem_original",  this.state.file);
              }

            const response = await fetch(api.baseURL + "/movimento_ovitrampa/" +
                (this.state.codigomovimento), {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    Authorization: localStorage.getItem("agToken"),
                },
                body: data,

                
            });



            const body = await response.json();

            if (response.status === 200) {
                this.setState({
                    swal: {
                        show: true,
                        title: "Tudo Certo",
                        text: "Laboratório enviado com sucesso.",
                        type: "success",
                    },
                    requesting: false,
                    isSuccess: true,
                });

            } else {
                this.setState({
                    swal: {
                        show: true,
                        title: "Erro",
                        text: body.erro,
                        type: "error",
                    },
                    selectedOs: null,
                    requesting: false,
                });
            }

        }
        catch (err) {
            // not jumping in here.
            console.log(err)
            this.setState({
                swal: {
                    show: true,
                    title: "Erro",
                    text: "Erro ao cadastrar Laboratório",
                    type: "error",
                },
                selectedOs: null,
                requesting: false,
            });
        }
    };

    base64ToBinary(base64) {
        if (typeof base64 !== 'string') {
            console.error('Expected base64 string, got:', typeof base64);
            return null;  // Return null or handle error if not a string
        }
    
        // Check if base64 has the 'data:image/png;base64,' prefix and remove it
        const base64Data = base64.includes('data:image') ? base64.split(',')[1] : base64;
    
        // Decode base64 to binary string
        const binaryString = atob(base64Data);
    
        // Convert binary string to Uint8Array
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
    
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
    
        return bytes;  // Return as Uint8Array
    }


    validateControlFields(index, userId) {
        /* Validação obrigatoriedade */
        // var dateFormat = "DD/MM/YYYY";
        // if ((this.state.selectedData[index] === undefined || this.state.selectedData[index] === "")) {
        //     this.setState({
        //         swal: {
        //             show: true,
        //             title: "Atenção",
        //             text: "Informe o Ano na data do Registro",
        //             type: "warning",
        //         },
        //     });

        //     return false;
        // }
        // else
        // {
        //     if (
        //         !moment(this.state.selectedData[index], dateFormat, true).isValid()
        //       ) {
        //         this.setState({
        //           swal: {
        //             show: true,
        //             title: "Atenção",
        //             text: "Data do registro inválida",
        //             type: "error",
        //           },
        //         });
        //         return false;
        //       }
        // }



        return true;
    }




    handleClassLabel(team, index) {

        let estado = this.state.control.find(p => p.usuario === team.id).estado;
        let className = 'btn-zoo-alt'
        switch (estado) {
            case 'pendente':
                className = 'btn-zoo-war'
                break;
            case 'fechado':
                className = 'btn-zoo-suc'
                break;
            case 'aberto':
                className = 'btn-zoo-alt'
                break;
            default:
                break;
        }
        // this.handleButtonClassArray(index, className);
        return className;
    }


    componentDidMount() {
        // Define and call agentes function inside componentDidMount
        this.checkAndFetchAgents();
      }

      componentDidUpdate(prevProps, prevState) {
        if (
          this.state.control.length > 0 &&
          this.state.ovitramp.id > 0 &&
          prevState.control !== this.state.control
        ) {
          this.agentes(); // Only fetch agents if control has updated
        }
      }


      checkAndFetchAgents = () => {
        // Make sure the state control array has data
        if (this.state.control.length > 0 && this.state.ovitramp.id > 0) {
          this.agentes(); // Fetch agents when the condition is met
        }
      };
      
      agentes = async () => {
        try {
          const response = await fetch(Api.baseURL + "/usuario/agente", {
            headers: {
              Authorization: localStorage.getItem("agToken"),
            },
          });
    
          const body = await response.json();
    
          if (response.status === 200) {
            this.setState({
              agents: body,
              isLoading: false, // Stop loading when data is fetched
            });
          } else {
            this.setState({ isLoading: false });
          }
        } catch (error) {
          console.error("Error fetching agents:", error);
          this.setState({ isLoading: false });
        }
      };

    handleSave(event) {
        if (this.validateFields()) {
            this.callSave();
        }
    }

    blurEndereco = () => {
        this.consultarLatLong();
    };

    consultarLatLong = () => {
        this.setState({ isMapOk: false });
        const addres = `${this.state.logradouro}, ${this.state.numero}, ${this.state.bairro}, ${this.state.cep}`;
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${addres}&key=${gconf.key}`
        )
            .then((response) => response.json())
            .then((res) => {
                if (res && res.status === "OK") {
                    console.log(res);
                    if (
                        res.results.length > 0 &&
                        res.results[0].geometry &&
                        res.results[0].geometry.location
                    ) {
                        this.setState({
                            ...this.state,
                            lat: res.results[0].geometry.location.lat,
                            lon: res.results[0].geometry.location.lng,
                            isMapOk: true,
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({
                    ...this.state,
                    lat: -18.90971,
                    lon: -48.261921,
                    isMapOk: true,
                });
            });
    };

    onDrop(pictureFiles, pictureDataURLs) {
        this.setState(
          {
            foto: pictureDataURLs[pictureDataURLs.length - 1],
            pictures: [],
            file: pictureFiles[pictureFiles.length - 1],
          },
          () => console.log(this.state.feed)
        );
      }
    

    // onDrop(pictureFiles, pictureDataURLs, index) {
    //     const updatedSelectedFotos = [...this.state.selectedFoto];
    //     const base64Image = pictureDataURLs[pictureDataURLs.length - 1];
      
    //     // Convert base64 image to binary
    //     const binaryImage = this.convertBase64ToBinary(base64Image);
        
    //     updatedSelectedFotos[index] = binaryImage;
      
    //     this.setState({
    //       selectedFoto: updatedSelectedFotos,
    //       pictures: pictureFiles, // Optional: Track all pictures if needed
    //       file: pictureFiles[pictureFiles.length - 1], // The latest file
    //     }, () => {
    //       console.log(this.state.selectedFoto); // Debug to confirm array updates
    //     });
    //   }
      
      convertBase64ToBinary(base64String) {
        const binaryString = window.atob(base64String.split(',')[1]); // Remove the base64 prefix if needed
        const byteArray = new Uint8Array(binaryString.length);
      
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
      
        return byteArray;
      }
      
      convertBinaryToBase64(binaryData) {
        let binaryString = '';
        const len = binaryData.byteLength;
        for (let i = 0; i < len; i++) {
          binaryString += String.fromCharCode(binaryData[i]);
        }
      
        return 'data:image/png;base64,' + window.btoa(binaryString); // Use the correct image MIME type if necessary
      }
    renderInputs(value) {
        const inputs = [];

        this.state.control.map((team, index) => (

            inputs.push(

                <>
                    <Row>
                        <Col lg="12">
                            <FormGroup>
                            <label
                           
                            className="form-control-label"
                            htmlFor="input-address"
                            >
                               
                           {/* {team.id ? `Movimento : ${team.id}` : "Novo Movimento"} */}
                           {team.ds_semana_movimento?  `${team.ds_semana_movimento}° Semana: ` : "Nova Semana"}   
                                               </label>
                                    
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="text-center">
<Col lg="12">
  <FormGroup>
    {this.state.foto && team.active ? ( // Use the selectedFoto array to check for the photo
      <img
        style={{ cursor: "pointer" }}
        className="img-fluid"
        src={this.state.foto} // Convert binary back to base64
        alt="Imagem Notícia"
        onClick={() => this.setState({ isOpen: true })} // Open modal when clicked
      />
    ) : null}
  </FormGroup>
</Col>
</Row>


<Row>

<Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    {team.data_criacao? "Data: Ex: "+team.data_criacao : "Data"}
                                     {/* {moment(team.dt_ult_alter || new Date(), 'YYYY-MM-DD').format('DD/MM/YYYY')} */}
                                </label>
                                <InputMask
                                    className="form-control"
                                    mask="99/99/9999"
                                    id="input-data"
                                    type="text"
                                    disabled={team.active !== true}
                                    autoComplete="new-data"
                                    onChange={e => this.handleControlDataArray(index, e.target.value)}
                                    required
                                    
                                    value={
                                    team.active !== true
                                        ? moment(team.dt_ovitrampa_movimento || new Date(), 'YYYY-MM-DD').format('DD/MM/YYYY')
                                        : this.state.selectedData[index]
                                    }
                                />
                            </FormGroup>
                        </Col>

                        

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    {"Lado da foto"}
                                     {/* {moment(team.dt_ult_alter || new Date(), 'YYYY-MM-DD').format('DD/MM/YYYY')} */}
                                </label>
                                <InputMask
                                    className="form-control"
                                   // mask="99/99/9999"
                                    id="input-data"
                                    type="text"
                                    disabled={team.active !== true}
                                    autoComplete="new-data"
                                    //onChange={e => this.handleControlDataArray(index, e.target.value)}
                                   // required
                                    
                                    value={
                                    team.active !== true
                                        ? team.ds_lado
                                        : this.state.selectedData[index]
                                    }
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    {"Agente"}
                                     {/* {moment(team.dt_ult_alter || new Date(), 'YYYY-MM-DD').format('DD/MM/YYYY')} */}
                                </label>
                                <select
                            className="form-control "
                            id="input-agente"
                            value={team.oid_agente || ""}
                            disabled
                            onChange={(e) => {
                                const updatedAgents = [...this.state.selectedAgente];
                                updatedAgents[index] = {
                                ...updatedAgents[index],
                                id: e.target.value, // Store as oid_agente instead of id
                                nome:
                                    this.state.agents.find((agent) => agent.id === e.target.value)?.nome || "",
                                };
                            
                                this.setState({ selectedAgente: updatedAgents });
                            }}
                        >
                            <option value="">Selecione...</option>
                            {this.state.agents.map((agent) => (
                            <option key={agent.id} value={agent.id}>
                                {agent.nome}
                            </option>
                            ))}
                        </select>
                            </FormGroup>
                        </Col>

                       
        </Row>




{team.active  &&  (
  <ImageUploader
  withIcon={false}
  withLabel={false}
  buttonText=""
//   onChange={(pictureFiles, pictureDataURLs) => this.onDrop(pictureFiles, pictureDataURLs, index)} // Pass index here
onChange={this.onDrop}
imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
  fileSizeError="O arquivo deve ter até 5mb"
  fileTypeError="Extensão do arquivo não suportada"
  maxFileSize={5242880}
  buttonClassName={"zoo-feed-photo"}
/>
)}
{team.bi_imagem_original || team.bi_imagem_ia ? (
  <>
    {/* Images with Titles */}
    <Row className="mb-3">
      {/* Original Image */}
      {team.bi_imagem_original && (
        <Col lg="6" className="d-flex flex-column align-items-center">
          <h5 className="mb-2">Imagem Original</h5>
          <FormGroup>
            <img
              style={{
                width: "200px",
                height: "150px",
                objectFit: "cover",cursor: "pointer",
            }}
            onClick={() => this.toggleModal(team.bi_imagem_original)}
              className="img-fluid"
              src={`data:image/jpeg;base64,${team.bi_imagem_original}`}
              alt="Original Image"
            />
          </FormGroup>
        </Col>
      )}

      {/* IA Image */}
      {team.bi_imagem_ia && (
        <Col lg="6" className="d-flex flex-column align-items-center">
          <h5 className="mb-2">Imagem IA</h5>
          <FormGroup>
            <img
              style={{
                width: "200px",
                height: "150px",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => this.toggleModal(team.bi_imagem_ia)}
              className="img-fluid"
              src={`data:image/jpeg;base64,${team.bi_imagem_ia}`}
              alt="IA Processed Image"
            />
          </FormGroup>
        </Col>
      )}
    </Row>

    {/* Download Buttons with Padding Below */}
    <Row className="mb-4">
      {/* Download Original Image */}
      {/* {team.bi_imagem_original && (
        <Col lg="6" className="d-flex justify-content-center">
          <button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `data:image/jpeg;base64,${team.bi_imagem_original}`;
              link.download = "original_image.jpg";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            style={{ cursor: "pointer", padding: "8px 12px" }}
          >
            Download Original
          </button>
        </Col>
      )} */}

      {/* Download IA Image */}
      {/* {team.bi_imagem_ia && (
        <Col lg="6" className="d-flex justify-content-center">
          <button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `data:image/jpeg;base64,${team.bi_imagem_ia}`;
              link.download = "ia_image.jpg";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            style={{ cursor: "pointer", padding: "8px 12px" }}
          >
            Download IA
          </button>
        </Col>
      )} */}
    </Row>
  </>
) : null}


                    <Row>
                        
                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    NR_OVOS_VIAVEIS_LAB
                                </label>
                                <Input
                                    className="form-control"
                                    id="input-aedes"
                                    type="number"
                                    disabled={team.active2 !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-aedes"
                                    required
                                    onChange={e => this.handleControlAedesArray(index, e.target.value)}
                                    value={team.active2 !== true ? team.nr_ovos_viaveis_lab : this.state.selectedAedes[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    NR_OVOS_INVIAVEIS_LAB
    </label>
                                <Input
                                    className="form-control"
                                    id="input-alb"
                                    type="number"
                                    disabled={team.active2 !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-alb"
                                    required
                                    onChange={e => this.handleControlAlbobictusArray(index, e.target.value)}
                                    value={team.active2 !== true ? team.nr_ovos_inviaveis_lab : this.state.selectedAlbobictus[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    NR_OVOS_ECLODIDOS_LAB
    </label>
                                <Input
                                    className="form-control"
                                    id="input-larv"
                                    type="number"
                                    disabled={team.active2 !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-larv"
                                    required
                                    onChange={e => this.handleControlLarvasArray(index, e.target.value)}
                                    value={team.active2 !== true ? team.nr_ovos_eclodidos_lab : this.state.selectedLarvas[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    NR_OVOS_VIAVEIS_IA
                                </label>
                                <Input
                                    className="form-control"
                                    id="input-aedes"
                                    type="number"
                                    disabled={team.active !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-aedes"
                                    required
                                    onChange={e => this.handleControlAedesArray(index, e.target.value)}
                                    value={team.active !== true ? team.nr_ovos_viaveis_ia : this.state.selectedAedes[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    NR_OVOS_INVIAVEIS_IA
    </label>
                                <Input
                                    className="form-control"
                                    id="input-alb"
                                    type="number"
                                    disabled={team.active !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-alb"
                                    required
                                    onChange={e => this.handleControlAlbobictusArray(index, e.target.value)}
                                    value={team.active !== true ? team.nr_ovos_inviaveis_ia : this.state.selectedAlbobictus[index]}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    NR_OVOS_ECLODIDOS_IA
    </label>
                                <Input
                                    className="form-control"
                                    id="input-larv"
                                    type="number"
                                    disabled={team.active !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-larv"
                                    required
                                    onChange={e => this.handleControlLarvasArray(index, e.target.value)}
                                    value={team.active !== true ? team.nr_ovos_eclodidos_ia : this.state.selectedLarvas[index]}
                                />
                            </FormGroup>
                        </Col>
                       
                     
                    </Row>

                    <Row>

                    <Col lg="2">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                >
                                    NR_OUTROS_OVOS
    </label>
                                <Input
                                    className="form-control"
                                    id="input-out"
                                    type="number"
                                    disabled={team.active2 !== true}
                                    defaultValue={0}
                                    onInput={(e) => {
                                        e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                        )
                                            .toString()
                                            .slice(0, 7);
                                    }}
                                    autoComplete="new-outr"
                                    onChange={e => this.handleControlOutrosArray(index, e.target.value)}
                                    required
                                    value={team.active2 !== true ? team.nr_ovos_outros : this.state.selectedOutros[index]}
                                />
                            </FormGroup>
                        </Col>



                        <Col lg="4" key={team.id}>
          <FormGroup>
          <label
                                      className="form-control-label"
                                      htmlFor="input-last-dtnascimento"
                                    >
                                       Agente LAB
                                    </label>
            {team.active2 ? (
              <div>
                {this.state.isLoading ? (
                  <div>Carregando agentes...</div> // Show loading for agents only
                ) : (
                  <select
                    className="form-control "
                    id="input-agente"
                    value={this.state.selectedAgente[index]?.id || team.oid_agente_lab}
                    onChange={(e) => {
                        const updatedAgents = [...this.state.selectedAgente];
                        updatedAgents[index] = {
                          ...updatedAgents[index],
                          id: e.target.value, // Store as oid_agente instead of id
                          nome:
                            this.state.agents.find((agent) => agent.id === e.target.value)?.nome || "",
                        };
                      
                        this.setState({ selectedAgente: updatedAgents });
                      }}
                  >
                    <option value="">Selecione...</option>
                    {this.state.agents.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                        {agent.nome}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            ) : (
              <Input
                className="form-control-alternative"
                id="input-agente"
                type="text"
                disabled
                value={team.oid_agente}
              />
            )}
          </FormGroup>
        </Col>
        <Col lg="12" style={{ paddingTop: '2.1%' }}>
                            <FormGroup>
                                <Button
                                    className={team.active2 !== true ? 'btn-zoo-suc' : 'btn-zoo-alt'}
                                    color="primary"
                                    onClick={() => {
                                        this.handleSaveControl(index);
                                    }}
                                    type="button"
                                >
                                    {team.active2 !== true ? 'Enviado' : 'Enviar'}
                    </Button>
                            </FormGroup>
                        </Col>
        </Row>


                    <Row className="text-center">
                    {/* <Col lg="12" style={{ paddingTop: '2.1%' }}>
                            <FormGroup>
                                <Button
                                    className={team.active2 !== true ? 'btn-zoo-suc' : 'btn-zoo-alt'}
                                    color="primary"
                                    onClick={() => {
                                        this.handleSaveControl(index);
                                    }}
                                    type="button"
                                >
                                    {team.active2 !== true ? 'Enviado' : 'Enviar'}
                    </Button>
                            </FormGroup>
                        </Col> */}
</Row>



                    <hr className="my-4" />

                </>
            )
        )
        )
        return inputs;
    }


    validateFields() {
        /* Validação obrigatoriedade */

        if (
            this.state.codigo === undefined ||
            this.state.codigo === null ||
            this.state.codigo.length === 0
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Código.",
                    type: "warning",
                },
            });

            return false;
        } else {
            if (this.state.codigo === "0") {
                this.setState({
                    swal: {
                        show: true,
                        title: "Atenção",
                        text: "Código não pode ser igual a zero.",
                        type: "warning",
                    },
                });

                return false;
            }
        }

        if (this.state.nome === undefined || this.state.nome.length === 0) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o nome do proprietário da Ovitrampa.",
                    type: "warning",
                },
            });

            return false;
        }

        if (
            this.state.tel === undefined ||
            this.state.tel.replace(/\D/g, "").length === 0
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Telefone.",
                    type: "warning",
                },
            });

            return false;
        }

        if (this.state.situacao === undefined || this.state.situacao === "") {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe a situação da Ovitrampa.",
                    type: "warning",
                },
            });

            return false;
        }

        if (
            this.state.logradouro === undefined ||
            this.state.logradouro.length === 0
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Endereço.",
                    type: "warning",
                },
            });

            return false;
        }
        if (this.state.numero === undefined || this.state.numero.length === 0) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Número.",
                    type: "warning",
                },
            });

            return false;
        }
        if (
            this.state.selectedNeighborhood === undefined ||
            this.state.selectedNeighborhood === "" ||
            this.state.selectedNeighborhood === null
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Informe o Bairro.",
                    type: "warning",
                },
            });

            return false;
        }
        /* Validação obrigatoriedade */

        if (
            this.state.tel !== "" &&
            this.state.tel.replace(/\D/g, "").length !== 11 &&
            this.state.tel.replace(/\D/g, "").length !== 10
        ) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: "Preencha o telefone com DDD e nono Dígito caso haja.",
                    type: "warning",
                },
            });

            return false;
        }
        /* Validação integridade */

        return true;
    }

    createBody = () => {
    
        var data = new FormData();
        if (this.state.ovitramp) {
            var ovitrampa = {
                foto: this.state.foto !== null ? this.state.foto : null,
                cep:
                    this.state.cep !== ""
                        ? this.state.cep.replace(/\D/g, "")
                        : this.state.ovitramp.cep,
                situacao: this.state.situacao,
                nome:
                    this.state.nome !== "" ? this.state.nome : this.state.ovitramp.nome,
                tel:
                    this.state.tel !== ""
                        ? this.state.tel.replace(/\D/g, "")
                        : this.state.ovitramp.tel.replace(/\D/g, ""),
                cod_bairro:
                    this.state.selectedNeighborhood !== ""
                        ? this.state.selectedNeighborhood
                        : this.state.ovitramp.bairro_setor.nome_bairro,
                lado: this.state.lado,
                quarteirao: this.state.quarteirao,
                
                endereco: {
                    logradouro:
                        this.state.logradouro !== ""
                            ? this.state.logradouro
                            : this.state.ovitramp.endereco.logradouro,
                    bairro:
                        this.state.bairro !== ""
                            ? this.state.bairro
                            : this.state.ovitramp.endereco.bairro,
                    numero:
                        this.state.numero !== ""
                            ? this.state.numero
                            : this.state.ovitramp.endereco.numero,
                    complemento:
                        this.state.complemento !== ""
                            ? this.state.complemento
                            : this.state.ovitramp.endereco.complemento,
                },
                geolocalizacao: {
                    lat: (this.state.lat !== ""
                        ? this.state.lat
                        : this.state.ovitramp.lat
                    ).toString(),
                    lon: (this.state.lon !== ""
                        ? this.state.lon
                        : this.state.ovitramp.lon
                    ).toString(),
                },
                codigo: this.state.codigo
            };

            data.append("ovitrampa", JSON.stringify(ovitrampa));
            return data;
        } else {
            // eslint-disable-next-line no-redeclare
            var ovitrampa = {
                foto: this.state.foto !== null ? this.state.foto : null,
                cep: this.state.cep.replace(/\D/g, ""),
                nome: this.state.nome,
                tel: this.state.tel.replace(/\D/g, ""),
                endereco: {
                    logradouro: this.state.logradouro.trim(),
                    bairro: this.state.bairro,
                    numero: this.state.numero,
                    complemento: this.state.complemento,
                },
                cod_bairro:
                    this.state.selectedNeighborhood !== ""
                        ? this.state.selectedNeighborhood
                        : this.state.ovitramp.endereco.bairro,
                lado: this.state.lado,
                quarteirao: this.state.quarteirao,
                geolocalizacao: {
                    lat: this.state.lat.toString(),
                    lon: this.state.lon.toString(),
                },
                situacao: this.state.situacao,
                codigo: this.state.codigo
            };

            data.append("ovitrampa", JSON.stringify(ovitrampa));
            return data;
        }
    };

    callSave = async () => {
        console.log(this.createBody());
        const response = await fetch(
            Api.baseURL +
            "/ovitrampa/" +
            (this.state.ovitramp ? this.state.ovitramp.id : ""),
            {
                method: this.state.ovitramp ? "PUT" : "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: localStorage.getItem("agToken"),
                },
                body: this.createBody(),
            }
        );
        const body = await response.json();

        if (response.status === 200 && body.sucesso === true) {
            this.setState({
                swal: {
                    show: true,
                    title: "Tudo Certo",
                    text: this.state.ovitramp
                        ? "Edição realizada com sucesso!"
                        : "Cadastro realizado com sucesso!",
                    type: "success",
                },
                isSuccess: true,
            });
        } else if (body.sucesso === false) {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: this.state.ovitramp
                        ? body.msg
                        : body.msg,
                    type: "error",
                },
            });
            console.log(body.erro);
        } else {
            this.setState({
                swal: {
                    show: true,
                    title: "Atenção",
                    text: this.state.ovitramp
                        ? "Erro ao editar ovitrampa. "
                        : "Erro ao cadastrar ovitrampa.",
                    type: "warning",
                },
            });
            console.log(body.erro);
        }

        return body;
    };

   

    render() {
       
            const { bi_imagem_ia, bi_imagem_original } = this.props;
            const { isOpen, selectedImage } = this.state;
        
            const imageSrc = bi_imagem_ia || bi_imagem_original;
        return (
            <>
                <SweetAlert2
                    {...this.state.swal}
                    onConfirm={(result) => {
                        this.setState({
                            swal: {
                                show: false,
                            },
                        });
                        if (this.state.isSuccess) {
                            this.props.history.push("/admin/laboratorio");
                        }
                    }}
                />
                
                <Header />
                {/* Page content */}
                <Container className="mb-7 mt-5 " fluid>
                    <Row className="mb-3">
                        <Col className="order-xl-1">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">
                                                Ovitrampa:{" "} 
                                                {this.state.codigo
                                                    }
                                            </h3>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Nome:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined && this.state.ovitramp.nome != null
                                                        ? this.state.ovitramp.nome
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Logradouro:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined && this.state.ovitramp.logradouro != null
                                                        ? this.state.ovitramp.logradouro
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Bairro:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined && this.state.ovitramp.bairro != null
                                                        ? this.state.ovitramp.bairro
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Status:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined &&
                                                        this.state.ovitramp.status !== null
                                                        ? Util.capitalize(this.state.ovitramp.status)
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                            <h4
                                                style={{ marginTop: "5px", color: "darkGrey" }}
                                                className="mb-0"
                                            >
                                                Situação:{" "}
                                                <strong>
                                                    {this.state.ovitramp !== undefined &&
                                                        this.state.ovitramp.situacao !== null
                                                        ? Util.capitalize(this.state.ovitramp.situacao)
                                                        : "N/A"}
                                                </strong>
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                                  {/* <Form>
                                                    <h6 className="heading-small text-muted mb-4">
                                                      {this.state.formTitle} Movimento Ovitrampa
                                                    </h6>
                                                    <div className="pl-lg-4">
                                                      {this.state.id > 0 && (
                                                        <Row>
                                                          <Col lg="4">
                                                            <FormGroup>
                                                              <label
                                                                className="form-control-label"
                                                                htmlFor="input-nome"
                                                              >
                                                                ID
                                                              </label>
                                
                                                              <Input
                                                                className="form-control-alternative"
                                                                id="input-id"
                                                                type="number"
                                                                disabled
                                                                required
                                                                value={this.state.id}
                                                                onChange={(e) =>
                                                                  this.setState({ id: e.target.value })
                                                                }
                                                              />
                                                            </FormGroup>
                                                          </Col>
                                                        </Row>
                                                      )}
                                                    
                                
                                                      <Row>
                                                        <Col lg="12">
                                                          <FormGroup>
                                                            <label
                                                              className="form-control-label"
                                                              htmlFor="input-email"
                                                            >
                                                              Foto Original *
                                                            </label>
                                                            <ImageUploader
                                                              withIcon={false}
                                                              withLabel={false}
                                                              buttonText=""
                                                              onChange={this.onDrop}
                                                              imgExtension={[
                                                                ".jpg",
                                                                ".gif",
                                                                ".png",
                                                                ".gif",
                                                                ".jpeg",
                                                              ]}
                                                              fileSizeError=" O arquivo deve ter até 5mb"
                                                              fileTypeError=" Extensão do arquivo não suportada"
                                                              maxFileSize={5242880}
                                                              buttonClassName={"zoo-feed-photo"}
                                                            />{" "}
                                                          </FormGroup>
                                                        </Col>
                                                      </Row>
                                                      {this.state.foto !== "" && (
                                                      <Row className="text-center">
                                                        <Col lg="12">
                                                          <FormGroup>
                                                            <div style={{ position: "relative" }}>
                                                              
                                                          
                                                            </div>
                                                          </FormGroup>
                                                        </Col>
                                                      </Row>
                                                    )}
                                                      {this.state.foto !== "" && (
                                                        <Row className="text-center">
                                                          <Col lg="12">
                                                            <FormGroup>
                                                              {this.state.foto !== "" && (
                                                                <img
                                                                  style={{ cursor: "pointer" }}
                                                                  className="img-fluid"
                                                                  src={this.state.foto}
                                                                  alt="Imagem Notícia"
                                                                  onClick={() =>
                                                                    this.setState({ isOpen: true })
                                                                  }
                                                                />
                                                              )}
                                                            </FormGroup>
                                                          </Col>
                                                        </Row>
                                                      )}
                                                   
                                                    </div>
                                                    {localStorage.getItem("perm") === "coordenador" && (
                                                      <div className="text-center">
                                                        <Row>
                                                          <Col className="order-xl-1" xl="12">
                                                            <Button
                                                              className="mt-4 btn-zoo"
                                                              color="primary"
                                                              type="button"
                                                              onClick={this.handleSave}
                                                            >
                                                              <b>
                                                                {this.state.isEdicao
                                                                  ? "Salvar Alterações"
                                                                  : "Cadastrar"}
                                                              </b>
                                                            </Button>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    )}
                                                  </Form> */}
                                                </CardBody>

                                 {this.state.control != null && this.state.control.length > 0 && (
                                    <CardBody>
                                        <Form>

                                            <div>
                                                <hr className="my-4" />
                                            
                                                <h3 className="mb-4">
                                                    Laboratório 
                                          </h3>
                                                    <>
                                                    {this.state.control.length > 0 && this.state.ovitramp.id > 0 && (
                                                        this.renderInputs(4)
                                                    )}
                                                    {this.state.control.length === 0 && this.state.ovitramp.id > 0 && (
                                                        <Row className="align-items-center">
                                                            <Col xs="12">

                                                                <div className="text-center">
                                                                    <Spinner
                                                                        style={{ width: "3rem", height: "3rem" }}
                                                                        color="primary"
                                                                    />
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    )}
                                                </>
                                            </div>
                                                                        
                                        </Form>
                                    </CardBody>
                                )} 
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={isOpen} toggle={() => this.toggleModal(null)} className="custom-modal">
          <ModalBody>
            <div className="image-container">
              <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Full View" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal(null)}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>

        <style jsx>{`
          .custom-modal .modal-body {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 80vh;
            
          }
          .image-container {
            max-height: 75vh;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-y: auto;
          }
          .image-container img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        `}</style>
            </>
        );
    }
}
