import React from "react";


export default function Maps({dados}) {
   

  return (
    <div style={{ height: "100vh", width: "98%", margin: "0 auto", position: "fixed", left:"250px" }}>
      <div style={{ position: "relative" }}>
        <img 
          src={require("../../assets/img/mapa_uberlandia_limpo.png")}
          alt="Mapa de Uberlândia"
          style={{
            width: "1280px",
            height: "650px",
            objectFit: "contain"
          }}
        />
        
        {/* Mapeamento dos pontos */}
        
        {dados.length > 0 && dados.map((ponto) => (
          ponto.exibir == 1 &&
          <div
            key={ponto.id}
            style={{
              position: "absolute",
              left: `${ponto.x}px`,
              top: `${ponto.y}px`,
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              padding: "1px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)"
            }}
          >
            {ponto.texto}
          </div>
        ))}
      </div>
    </div>
  );
}