import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import MapsLeaflet from "../maps/maps_leaflet";
import { GoogleMap } from "components/Map/GoogleMap";
import SweetAlert2 from "react-sweetalert2";
import Api from "shared/resources/Api";
import Header from "components/Headers/Header.js";
import InputMask from "react-input-mask";
import { neighborhood } from "shared/services/PlacesService";
import InputNumber from "react-input-just-numbers";
import gconf from "shared/resources/Gconf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";

export default class OvitrampEdit extends React.Component {
  columns = [
    {
      name: "Endereço",
      selector: "endereco",
      sortable: true,
    },
    {
      name: "Bairro",
      selector: "bairro",
      sortable: true,
      cell: (row) => <div>{row.bairro ? row.bairro : "-"}</div>,
    },
    {
      name: "Setor",
      selector: "setor",
      sortable: true,
      cell: (row) => <div>{row.setor ? row.setor : "-"}</div>,
    },
    {
      name: "Ação",
      cell: (row) => (
        <div>
          <Button
            type="button"
            className="btn-acao "
            title="Escolher"
            onClick={() => {
              this.handleSelectedAddress(row)
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="edit" />
            Escolher
          </Button>
        </div>
      ),
    },
  ];
  defaultComponentOptions = {
    rowsPerPageText: "Registros por Página:",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
  };
  customStyles = {
    // cells: {
    //   style: {
    //     backgroundColor: "#F7FAFC"
    //   },
    // },
    table: {
      style: {
        backgroundColor: "#F7FAFC",
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: 15,
        color: "#000",
        backgroundColor: "#F7FAFC"
      },
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      cep: "",
      nome: "",
      // blurEndereco: this.blurEndereco.bind(this),
      // consultarLatLong: this.consultarLatLong.bind(this),
      responseAddress: "",
      selectedAddress: "",
      tel: "",
      logradouro: "",
      numero: "",
      bairro: "",
      setor: "",
      complemento: "",
      lat: -18.90971,
      lon: -48.261921,
      api: Api.baseURL,
      isMapOk: false,
      isEdicao: false,
      formTitle: "CADASTRAR",
      neighborhood: [],
      sectors: [],
      selectedSector: null,
      selectedNeighborhood: "",
      lado: null,
      quarteirao: null,
      situacao: "",
      codigo: null
    };

    this.handleSave = this.handleSave.bind(this);
  }

  handleSelectedAddress = (row) => {
    console.log(row, 'escolheu')
    this.setState({ selectedAddress: row, logradouro: row.endereco.trim(), bairro: row.bairro, setor: row.setor });
  };


  handleSiat = (event) => {

    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Logradouro.",
          type: "warning",
        },
      });

      return false;
    } else {
      // consultarLatLong()
      if (this.state.selectedAddress === "") {
        this.setState({ requesting: true });
        

        this.fetchSiat()
          .then((res) => this.setState({ responseAddress: res }))
          .catch((err) => {
            console.log(err);
            this.setState({
              swal: {
                show: true,
                title: "Atenção",
                text:
                  "Não foram encontrados endereços para os parâmetros informados.",
                type: "error",
              },
              requesting: false,
            });
          });
      } else {
        this.setState({ selectedAddress: "", logradouro: "", bairro: "", responseAddress: "" });
      }
    }




    event.preventDefault();
  };

  fetchSiat = async () => {
    let host = this.state.api + "/siat_enderecos?";

    let params = {
      endereco: this.state.logradouro,
      bairro: this.state.bairro ? this.state.bairro : ""
    };

    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const response = await fetch(host + query, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("agToken"),
      },
    });

    const body = await response.json();

    if (body.length > 0) {
      this.setState({
        requesting: false,
      });
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text:
            "Não foram encontrados endereços para os parâmetros informados.",
          type: "error",
        },
        requesting: false,
      });
    }
    return body;
  };


  componentWillMount() {
    neighborhood().then((res) => {
      if (res) {
        this.setState({
          neighborhood: res,
        });
      }
    });
    if (
      this.props.location != null &&
      this.props.location.state != null &&
      this.props.location.state.ovitramp != null
    ) {
      this.setState(
        {
          ovitramp: this.props.location.state.ovitramp,
          agent: this.props.location.state.os,
          loading: false,
          nome: this.props.location.state.ovitramp.nome,
          lado: this.props.location.state.ovitramp.lado,
          quarteirao: this.props.location.state.ovitramp.quarteirao,
          bairro: this.props.location.state.ovitramp.bairro,
          tipo: this.props.location.state.ovitramp.tipo,
          status: this.props.location.state.ovitramp.status,
          tel: this.props.location.state.ovitramp.tel,
          matricula: this.props.location.state.ovitramp.matricula,
          logradouro: this.props.location.state.ovitramp.endereco.logradouro.trim(),
          numero: this.props.location.state.ovitramp.endereco.numero,
          complemento: this.props.location.state.ovitramp.endereco.complemento,
          email: this.props.location.state.ovitramp.email,
          lat: this.props.location.state.ovitramp.geolocalizacao.lat,
          lon: this.props.location.state.ovitramp.geolocalizacao.lon,
          isMapOk: true,
          isEdicao: true,
          formTitle: "EDITAR",
          situacao: this.props.location.state.ovitramp.situacao,
          codigo: this.props.location.state.ovitramp.id
        },
        () => console.log("state logou end")
      );
    }
  }

  componentDidMount() { }

  // consultarCep = () => {
  //   if (this.state.cep && this.state.cep.replace(/\D/g, "").length === 8) {
  //     viaCep(this.state.cep)
  //       .then((res) => {
  //         if (res && !res.erro) {
  //           const idx = this.state.neighborhood.findIndex(
  //             (p) => p.nome === res.bairro
  //           );
  //           this.setState({
  //             ...this.state,
  //             bairro: res.bairro,
  //             complemento: res.complemento,
  //             logradouro: res.logradouro,
  //             selectedNeighborhood:
  //               idx > -1
  //                 ? this.state.neighborhood[idx].id
  //                 : this.state.selectedNeighborhood,
  //           });
  //           this.consultarLatLong();
  //         } else {
  //           this.setState({
  //             swal: {
  //               show: true,
  //               title: "Atenção",
  //               text: "Nenhum Endereço localizado.",
  //               type: "warning",
  //             },
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           swal: {
  //             show: true,
  //             title: "Atenção",
  //             text: "Nenhum Endereço localizado.",
  //             type: "warning",
  //           },
  //         });
  //         console.log(err);
  //       });
  //   }
  // };
  handleSave(event) {
    if (this.validateFields()) {
      this.callSave();
    }
  }

  blurEndereco = () => {
    this.setState({}, () => {
      this.consultarLatLong();
    });
  };

  consultarLatLong = () => {
    this.setState({ isMapOk: false });
    const addres = `${this.state.logradouro}, ${this.state.numero}, ${this.state.bairro}, ${this.state.cep}`;
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${addres}&key=${gconf.key}`
    )
      .then((response) => response.json())
      .then((res) => {
        if (res && res.status === "OK") {
          console.log(res);
          if (
            res.results.length > 0 &&
            res.results[0].geometry &&
            res.results[0].geometry.location
          ) {
            this.setState({
              ...this.state,
              lat: res.results[0].geometry.location.lat,
              lon: res.results[0].geometry.location.lng,
              isMapOk: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          lat: -18.90971,
          lon: -48.261921,
          isMapOk: true,
        });
      });
  };
  

  validateFields() {
    /* Validação obrigatoriedade */

    if (
      this.state.codigo === undefined ||
      this.state.codigo === null ||
      this.state.codigo.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Código.",
          type: "warning",
        },
      });

      return false;
    } else {
      if (this.state.codigo === "0") {
        this.setState({
          swal: {
            show: true,
            title: "Atenção",
            text: "Código não pode ser igual a zero.",
            type: "warning",
          },
        });

        return false;
      }
    }

    if (this.state.nome === undefined || this.state.nome.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o nome do proprietário da Ovitrampa.",
          type: "warning",
        },
      });

      return false;
    }

    if (
      this.state.tel === undefined ||
      this.state.tel.replace(/\D/g, "").length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Telefone.",
          type: "warning",
        },
      });

      return false;
    }




    if (
      this.state.logradouro === undefined ||
      this.state.logradouro.length === 0
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Endereço.",
          type: "warning",
        },
      });

      return false;
    }
    if (this.state.numero === undefined || this.state.numero.length === 0) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Número.",
          type: "warning",
        },
      });

      return false;
    }
    if (
      this.state.bairro === undefined ||
      this.state.bairro === "" ||
      this.state.bairro === null
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Informe o Bairro.",
          type: "warning",
        },
      });

      return false;
    }
    /* Validação obrigatoriedade */

    if (
      this.state.tel !== "" &&
      this.state.tel.replace(/\D/g, "").length !== 11 &&
      this.state.tel.replace(/\D/g, "").length !== 10
    ) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: "Preencha o telefone com DDD e nono Dígito caso haja.",
          type: "warning",
        },
      });

      return false;
    }
    /* Validação integridade */

    return true;
  }

  createBody = () => {
    console.log(this.state.situacao)
    var data = new FormData();
    if (this.state.ovitramp) {
      var ovitrampa = {
        nome:
          this.state.nome !== "" ? this.state.nome : this.state.ovitramp.nome,
        tel:
          this.state.tel !== ""
            ? this.state.tel.replace(/\D/g, "")
            : this.state.ovitramp.tel.replace(/\D/g, ""),
        setor:
          this.state.setor !== ""
            ? this.state.setor
            : "",
        bairro:
          this.state.bairro !== ""
            ? this.state.bairro
            : "",
        lado: this.state.lado,
        status: this.state.status,
        logradouro: this.state.logradouro.trim(),
        quarteirao: this.state.quarteirao !== null && this.state.quarteirao !== undefined ? this.state.quarteirao : 0,
        numero: this.state.numero,
        complemento: this.state.complemento,
        geolocalizacao: {
          lat: (this.state.lat !== ""
            ? this.state.lat
            : this.state.ovitramp.lat
          ).toString(),
          lon: (this.state.lon !== ""
            ? this.state.lon
            : this.state.ovitramp.lon
          ).toString(),
        },
        codigo: this.state.codigo
      };

      data.append("ovitrampa", JSON.stringify(ovitrampa));
      return data;
    } else {
      // eslint-disable-next-line no-redeclare
      var ovitrampa = {
        nome: this.state.nome,
        tel: this.state.tel.replace(/\D/g, ""),
        bairro:
          this.state.bairro !== ""
            ? this.state.bairro
            : "",
        setor:
          this.state.setor !== ""
            ? this.state.setor
            : "",
        lado: this.state.lado,
        logradouro: this.state.logradouro.trim(),
        quarteirao: this.state.quarteirao !== null && this.state.quarteirao !== undefined ? this.state.quarteirao : 0,
        numero: this.state.numero,
        complemento: this.state.complemento,
        geolocalizacao: {
          lat: this.state.lat.toString(),
          lon: this.state.lon.toString(),
        },
        codigo: this.state.codigo
      };

      data.append("ovitrampa", JSON.stringify(ovitrampa));
      return data;
    }
  };

  callSave = async () => {
    console.log(this.createBody());
    const response = await fetch(
      Api.baseURL +
      "/ovitrampa/" +
      (this.state.ovitramp ? this.state.ovitramp.id : ""),
      {
        method: this.state.ovitramp ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          Authorization: localStorage.getItem("agToken"),
        },
        body: this.createBody(),
      }
    );
    const body = await response.json();

    if (response.status === 200 && body.sucesso === true) {
      this.setState({
        swal: {
          show: true,
          title: "Tudo Certo",
          text: this.state.ovitramp
            ? "Edição realizada com sucesso!"
            : "Cadastro realizado com sucesso!",
          type: "success",
        },
        isSuccess: true,
      });
    } else if (body.sucesso === false) {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.ovitramp
            ? body.msg
            : body.msg,
          type: "error",
        },
      });
      console.log(body.erro);
    } else {
      this.setState({
        swal: {
          show: true,
          title: "Atenção",
          text: this.state.ovitramp
            ? "Erro ao editar ovitrampa. "
            : "Código já existe.",
          type: "warning",
        },
      });
      console.log(body.erro);
    }

    return body;
  };
  render() {
    return (
      <>
        <SweetAlert2
          {...this.state.swal}
          onConfirm={(result) => {
            this.setState({
              swal: {
                show: false,
              },
            });
            if (this.state.isSuccess) {
              this.props.history.push("/admin/ovitramp");
            }
          }}
        />
        <Header />
        {/* Page content */}
        <Container className="mb-7 mt-5 " fluid>
          <Row className="mb-3">
            <Col className="order-xl-1">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {this.state.formTitle} Ovitrampa
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Código Ovitrampa *
                            </label>

                            <InputNumber
                              disabled={this.state.isEdicao}
                              onlydigits
                              className="form-control"
                              id="input-codigo"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 6);
                              }}
                              placeholder=""
                              value={this.state.codigo}
                              onChange={(e) =>
                                this.setState({ codigo: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nome Proprietário *
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-nome"
                              type="text"
                              required
                              value={this.state.nome}
                              onChange={(e) =>
                                this.setState({ nome: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Telefone *
                            </label>
                            <InputMask
                              mask="(99) 99999-9999"
                              type="text"
                              name="tel"
                              id="tel"
                              inputMode="numeric"
                              className="form-control"
                              value={this.state.tel}
                              onChange={(e) =>
                                this.setState({ tel: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="2">
                          <FormGroup>
                            <div>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-dtnascimento"
                              >
                                Status *
                              </label>

                              <select
                                value={this.state.status}
                                disabled={!this.state.isEdicao}
                                onChange={(e) =>
                                  this.setState({
                                    status: e.target.value,
                                  })
                                }
                                className="form-control "
                              >
                                <option defaultValue="" value="">
                                  {" "}
                                  Selecione...{" "}
                                </option>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                              </select>
                            </div>
                          </FormGroup>
                        </Col>

                      </Row>


                      <Row>
                      <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address">
                          Logradouro *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          disabled={this.state.selectedAddress !== ""}
                          placeholder=""
                          type="text"
                          value={this.state.logradouro}
                          onChange={(e) => this.setState({ logradouro: e.target.value })}
                          onBlur={this.blurEndereco} // Call onBlur instead of onChange
                        />
                      </FormGroup>
                    </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bairro"
                            >
                              Bairro *
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-lado"
                              type="text"
                              maxLength="40"
                              disabled={this.state.selectedAddress !== ""}
                              value={this.state.bairro}
                              onChange={(e) =>
                                this.setState({ bairro: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Número *
                                </label>
                            <Input
                              className="form-control-alternative"
                              id="input-number"
                              placeholder=""
                              type="text"
                              value={this.state.numero}
                              onChange={(e) =>
                                this.setState({ numero: e.target.value })
                              }
                              onBlur={this.blurEndereco}
                            />
                          </FormGroup>
                        </Col>


                      </Row>

                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-complement"
                            >
                              Complemento
                                </label>
                            <Input
                              className="form-control-alternative"
                              id="input-complement"
                              placeholder=""
                              type="text"
                              maxLength={20}
                              value={this.state.complemento}
                              onChange={(e) =>
                                this.setState({
                                  complemento: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Quarteirão
                            </label>

                            <InputNumber
                              onlydigits
                              className="form-control"
                              id="input-quarteirao"
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                              placeholder=""
                              value={this.state.quarteirao}
                              onChange={(e) =>
                                this.setState({ quarteirao: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp"
                            >
                              Lado
                            </label>

                            <Input
                              className="form-control-alternative"
                              id="input-comp"
                              type="text"
                              required
                              maxLength={4}
                              value={this.state.lado}
                              onChange={(e) =>
                                this.setState({ lado: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <div className="text-left mt-4 pt-2">
                              <Button
                                className={this.state.selectedAddress === "" ? "btn-zoo-alt" : "btn-zoo"}
                                color="primary"
                                type="button"
                                onClick={this.handleSiat}
                              >
                                {this.state.selectedAddress === "" ? "Consultar Endereço" : "Alterar Endereço"}

                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      {this.state.responseAddress && !this.state.requesting && this.state.selectedAddress === "" && (
                        <DataTable
                          noHeader={true}
                          pagination={true}
                          striped={true}
                          columns={this.columns}
                          sortable={true}
                          responsive={true}
                          highlightOnHover={true}
                          data={this.state.responseAddress}
                          noDataComponent="Nenhum registro encontrado."
                          paginationComponentOptions={this.defaultComponentOptions}
                          customStyles={this.customStyles}
                        />
                      )}

                      {this.state.requesting && (
                        <div className="text-center">
                          <Spinner
                            style={{ width: "3rem", height: "3rem" }}
                            color="primary"
                          />

                        </div>

                      )}


                    </div>
                    {this.state.isMapOk && (
                      <Row>
                        <Col lg="12" style={{ height: "40vh", paddingLeft: 0 }}>
                          <GoogleMap
                            lat={this.state.lat}
                            lng={this.state.lon}
                          />
                        </Col>
                      </Row>
                    )}
                    {localStorage.getItem("perm") !== "agente" && (
                      <div className="text-center">
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <Button
                              className="mt-4 btn-zoo"
                              color="primary"
                              type="button"
                              onClick={this.handleSave}
                            >
                              <b>
                                {this.state.isEdicao
                                  ? "Salvar Alterações"
                                  : "Cadastrar"}
                              </b>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
