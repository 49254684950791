import React, { useState, useEffect } from "react";
import ShowBar from "./bar";
import Radar from "./radar";
import Maps from "../maps/maps";
import Api from "shared/resources/Api";
import { DataMap, calcZone, getType } from "../maps/dataMap";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody
} from "reactstrap";
import "react-image-lightbox/style.css";

const ShowGraphics = () => {

  const callApi = async () => {

    let response = await fetch(Api.baseURL + '/dados/dengue', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('agToken'),
      },
    });
    let body = await response.json();
    let tmpData = { 'day': DataMap(formataDados(body.dados.day)), 'week': DataMap(formataDados(body.dados.week)), 'month': DataMap(formataDados(body.dados.month)), 'year': DataMap(formataDados(body.dados.year)) };

    let tmp = DataMap(tmpData[filtroTempo]);
    setDados(tmpData);
    setDadosMapa(tmpData);
    setZone({ 'day': calcZone(tmpData.day), 'week': calcZone(tmpData.week), 'month': calcZone(tmpData.month), 'year': calcZone(tmpData.year) });


    return true;
  }


  const formataDados = (dados = []) => {
    let tmp = [];
    if (dados.length == 0) {
      return [];
    }
    dados.forEach(item => {
      tmp.push({
        type: item.nm_bairro,
        value: item.total_registros
      });
    });
    return tmp;
  }
  const formataDadosMapa = (dados = [], setor) => {
    let tmp = [];
    if (dados.length == 0) {
      return [];
    }
    dados.forEach(item => {
      if (parseInt(item.texto) > 0 && item.setor == setor) {
        tmp.push({
          type: item.nome,
          value: parseInt(item.texto)
        });
      }
    });
    /*  tmp.sort((a, b) => {
      const valorA = parseInt(a.value) || 0;
      const valorB = parseInt(b.value) || 0;
      return valorB - valorA;
    }); 
    Caso queira apresentar em ordem crescente.
    */

    return tmp;
  }
  const casos = [
    { name: 'CENTRO', Casos: 0 },
    { name: 'NORTE', Casos: 0 },
    { name: 'SUL', Casos: 0 },
    { name: 'LESTE', Casos: 0 },
    { name: 'OESTE', Casos: 0 },
    { name: 'RURAL', Casos: 0 },
    { name: 'DISTRITO', Casos: 0 },
    { name: 'NAO IDENTIFICADO', Casos: 0 }

  ];
  const [showMap, setShowMap] = useState(false);
  const [zone, setZone] = useState({ 'day': casos, 'week': casos, 'month': casos, 'year': casos });
  const [filtroTempo, setFiltroTempo] = useState('day');

  const [dados, setDados] = useState({ 'day': [], 'week': [], 'month': [], 'year': [] });
  const [dadosMapa, setDadosMapa] = useState([]);



  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      callApi();
    }, 20000);

    return () => clearInterval(interval);
  }, []);



  return (
    <>
      {showMap && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            zIndex: 1000,
            padding: '20px',
            borderRadius: '5px',
            border: '5px solid rgb(24, 144, 255)',
            borderBottom: 'none'
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setShowMap(false);
            }
          }}
          tabIndex={0}
        >
          <button
            className="btn btn-danger mb-3"
            onClick={() => setShowMap(false)}
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              cursor: 'pointer',
              zIndex: 1001 // Garante que o botão fique acima do conteúdo
            }}
            type="button" // Explicita que é um botão clicável
          >
            Fechar
          </button>
          <div style={{ marginLeft: "150px", left:"280px" }}>
            <Maps dados={dadosMapa[filtroTempo]} />
          </div>

          <div className="d-flex align-items-start mt-4">
  {/* Table Container */}
  <div className="col-md-2">
    <h3 style={{ whiteSpace: "nowrap" }}>A90 - DENGUE {getType(filtroTempo)}</h3>
    <table className="table table-bordered" style={{ borderRadius: "15px", overflow: "hidden" }}>
      <thead>
        <tr style={{ backgroundColor: "#1890ff", color: "white" }}>
          <th>SETOR</th>
          <th>Quantidade de Casos</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ backgroundColor: "#e6f7ff" }}>
          <td><strong>Centro</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][0].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#bae7ff" }}>
          <td><strong>Norte</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][1].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#91d5ff" }}>
          <td><strong>Sul</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][2].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#69c0ff" }}>
          <td><strong>Leste</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][3].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#40a9ff" }}>
          <td><strong>Oeste</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][4].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#1890ff", color: "white" }}>
          <td><strong>Rural</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][5].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#0050b3", color: "white" }}>
          <td><strong>Distrito</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][6].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#002766", color: "white" }}>
          <td><strong>Não Identificado</strong></td>
          <td style={{ textAlign: "right" }}><strong>{zone[filtroTempo][7].Casos}</strong></td>
        </tr>
        <tr style={{ backgroundColor: "#001529", color: "white" }}>
          <td><strong>Total</strong></td>
          <td style={{ textAlign: "right" }}>
            <strong>
              {zone[filtroTempo].reduce((acc, curr) => acc + curr.Casos, 0)}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  {/* Select Container - Positioned to the Right */}
  <div className="col-md-2 d-flex" style={{minWidth: "200px", left:"8%"}}>
    <select
      className="form-control"
      style={{ width: "200px" }}
      defaultValue={filtroTempo}
      onChange={(e) => {
        const value = e.target.value;
        localStorage.setItem("filtroTempo", value);
        setFiltroTempo(value);
      }}
    >
      <option value="day">Diário</option>
      <option value="week">Semanal</option>
      <option value="month">Mensal</option>
      <option value="year">Anual</option>
    </select>
  </div>
</div>
          
        </div>
        
      )}

      <Container className="mb-7 mt-5" fluid>
        <div className="mb-3" style={{ height: "60px" }}></div>
        {/* <h3>Tipo de filtro:</h3> */}

        <div className="d-flex align-items-center">
          {/* <select className="form-control mb-3 mr-3"
            style={{ width: '200px' }}
            defaultValue={filtroTempo}
            onChange={(e) => {
              const value = e.target.value;
              localStorage.setItem('filtroTempo', value);
              setFiltroTempo(value);
             }}>
            <option value="day">Diário</option>
            <option value="week">Semanal</option>
            <option value="month">Mensal</option>
            <option value="year">Anual</option>
          </select> */}

            <button
              className="btn btn-primary mb-3"
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setShowMap(false);
                }
              }}
              onClick={() => {
                localStorage.setItem("filtroTempo", "day");
                setShowMap(!showMap);
              }}
            >
              Mostrar mapa
            </button>
        </div>
        <div className="mb-3" style={{ height: "11vw" }}></div>


      </Container>
    </>
  );
};

export default ShowGraphics;